<template>
  <div class="chart-container">
    <q-tabs
      v-model="currentTab"
      inline-label
      class="text-grey-7"
      active-color="primary"
      indicator-color="primary"
    >
      <q-tab
        v-for="chart in chartsData"
        :key="chart.id"
        :name="chart.id"
        :label="chart.label"
      />
    </q-tabs>

    <div class="chart-controls">
      <q-select
        v-model="selectedTimePeriod"
        :options="timePeriodOptions"
        :label="$t('dashboard.charts.timeFrame')"
        class="q-mb-md"
        outlined
        dense
        @update:model-value="handleTimePeriodChange"
      />
    </div>

    <q-tab-panels v-model="currentTab" animated class="chart-data">
      <q-tab-panel
        v-for="chart in chartsData"
        :key="chart.id"
        :name="chart.id"
        class="chart-data__wrapper"
      >
        <div v-if="!viewsLoading && chart.data.length">
          <ChartTabsItem
            :data="chart.data"
            :label="chart.label"
            :title="chart.title"
            :backgroundColor="chart.backgroundColor"
            :borderColor="chart.borderColor"
            :timePeriod="selectedTimePeriod.value"
          />
        </div>
        <div v-else-if="viewsLoading" class="chart-data__loading">
          <q-spinner color="primary" size="3em" />
        </div>
        <div v-else class="chart-data__error">
          <p>{{ $t("error.noDashboardData") }}</p>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import ChartTabsItem from "./ChartTabsItem.vue";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "ChartTabs",
  components: { ChartTabsItem },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentTab: null,
      selectedTimePeriod: {
        label: this.$t("dashboard.charts.week"),
        value: "week",
      },
    };
  },
  computed: {
    ...mapState("dashboardEvents", ["currentEvent"]),
    ...mapGetters("dashboardEvents", ["getCurrency"]),
    ...mapState("dashboardCharts", [
      "viewsData",
      "viewsLoading",
      "viewsError",
      "salesData",
      "salesLoading",
      "salesError",
      "ordersData",
      "ordersLoading",
      "ordersError",
    ]),
    timePeriodOptions() {
      return [
        { label: this.$t("dashboard.charts.day"), value: "day" },
        { label: this.$t("dashboard.charts.week"), value: "week" },
        { label: this.$t("dashboard.charts.month"), value: "month" },
        { label: this.$t("dashboard.charts.total"), value: "total" },
      ];
    },
    chartsData() {
      return [
        {
          id: "totalOrders",
          data: this.ordersData || [],
          label: this.$t("dashboard.main.totalOrders"),
          title: this.$t("dashboard.main.totalOrders"),
          backgroundColor: "rgba(255, 99, 132, 1)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
        {
          id: "albumViews",
          data: this.viewsData || [],
          label: this.$t("dashboard.main.albumViews"),
          title: this.$t("dashboard.main.albumViews"),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
        },
        {
          id: "totalSales",
          data: this.salesData || [],
          label: this.$t("dashboard.main.totalAlbumSalesAmount"),
          title: this.$t("dashboard.main.totalAlbumSalesAmount"),
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: "rgba(255, 206, 86, 1)",
        },
      ];
    },
  },
  watch: {
    chartsData: {
      immediate: true,
      handler(newData) {
        if (newData.length && !this.currentTab) {
          this.currentTab = newData[0].id;
        }
      },
    },
    getCurrency(newCurrency) {
      this.fetchInitialData(newCurrency);
    },
  },
  mounted() {
    this.fetchInitialData(this.getCurrency);
  },
  methods: {
    ...mapActions("dashboardCharts", [
      "fetchViewsByTimePeriod",
      "fetchSalesData",
      "fetchOrdersData",
    ]),

    handleTimePeriodChange(newTimePeriod) {
      this.selectedTimePeriod = newTimePeriod;

      this.fetchViewsByTimePeriod({
        wpRunId: this.eventId,
        timePeriod: newTimePeriod.value,
      });

      this.fetchSalesData({
        wpRunId: this.eventId,
        timePeriod: newTimePeriod.value,
        currency: this.getCurrency,
        type: "sales",
      });

      this.fetchOrdersData({
        wpRunId: this.eventId,
        timePeriod: newTimePeriod.value,
        currency: this.getCurrency,
        type: "orders",
      });
    },
    fetchInitialData() {
      this.fetchViewsByTimePeriod({
        wpRunId: this.eventId,
        timePeriod: this.selectedTimePeriod.value,
      });

      this.fetchSalesData({
        wpRunId: this.eventId,
        timePeriod: this.selectedTimePeriod.value,
        currency: this.getCurrency,
        type: "sales",
      });

      this.fetchOrdersData({
        wpRunId: this.eventId,
        timePeriod: this.selectedTimePeriod.value,
        currency: this.getCurrency,
        type: "orders",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  display: flex;
  flex-direction: column;
  .q-tabs div {
    width: 100%;
  }
}
.chart-data {
  &__wrapper {
    min-height: 284px;
    display: flex;
    justify-content: center;
    & div {
      width: 100%;
    }
  }
  &__loading,
  &__error {
    margin: auto;
    text-align: center;
  }
  &__error {
    p {
      font-size: 1.5rem;
    }
  }
}
.q-tab {
  background: #ebebeb;
  color: #000;
  font-weight: 600;
  height: 50px;
}
.chart-controls {
  width: 160px;
  align-self: flex-end;
}
</style>
