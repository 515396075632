<template>
  <div class="user-integration__wrapper">
    <div class="user-integration__block">
      <h3 class="text-h5">{{ $t('userPanel.integration.successTitle') }}</h3>
      <p>{{ $t('userPanel.integration.successMessage') }}</p>
      <div class="email-block q-mb-lg">
        <span class="q-mr-sm text-bold">{{ $t('userPanel.integration.emailLabel') }}</span>
        <input type="text" :value="user?.useme_email" disabled />
      </div>
      <p>{{ $t('userPanel.integration.approvalMessage') }}</p>
      <p>
        {{ $t('userPanel.integration.payoutDetails') }}
        <span>
          <a href="https://useme.com">{{ $t('userPanel.integration.officialSite') }}</a>
        </span>
      </p>
      <p>
        {{ $t('userPanel.integration.resetPasswordMessage') }}
        <span>
          <a href="https://useme.com/en/users/password/recover/">
            {{ $t('userPanel.integration.resetPasswordLink') }}
          </a>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IntegrationSuccessInformation",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>

<style lang="scss" scoped>
.email-block {
  display: flex;
  align-items: center;
}
.user-integration {
  &__wrapper {
    width: 100%;
    padding: 1rem;
    display: flex;
    p {
      font-size: 1rem;
    }
  }
}

@media (min-width: 1024px) {
  .user-integration {
    &__wrapper {
      flex-direction: column;
      align-items: center;
      padding: 1rem 4rem;
    }
    &__block {
      border-left: none;
      padding: 0 6px;
      margin: 0;
      width: 100%;
      max-width: 550px;
    }
  }
}
</style>
