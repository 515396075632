import api from '@/api/api';

export const dashboardEvents = {
  namespaced: true,
  state: {
    eventId: null,
    loading: false,
    error: false,
    isSidebarOpen: false,
    currentEvent: JSON.parse(localStorage.getItem('currentEvent')) || null,
    events: JSON.parse(localStorage.getItem('events')) || [],
    unverifiedEvents: JSON.parse(localStorage.getItem('unverifiedEvents')) || [],
    currency: 'PLN',
    currencies: [],
    totalPhotosInEvent: 0,
    //orders table
    ordersData: [],
    ordersLoading: false,
    ordersError: null,
    ordersTableItemCount: 0,
    totalOrganizatorCommission: 0,
    organizatorCommissionPerCurrency: 0,
    pagination: {
      totalPages: 1,
      currentPage: 1
    },
    links: [
      { icon: 'share', text: 'dashboard.menu.shares' },
      { icon: 'shopping_cart', text: 'dashboard.main.totalOrders' },
    ],
  },
  getters: {
    getCurrentEvent: (state) => {
      if (!state.currentEvent && state.eventId) {
        return (
          state.events.find((event) => event.id === parseInt(state.eventId)) || null
        );
      }
      return state.currentEvent;
    },
    getCurrency: (state) => state.currency,
    getCurrencies: (state) => state.currencies,
    isSidebarOpen: (state) => state.isSidebarOpen,
  },
  actions: {
    async fetchCurrencies({ commit }) {
      try {
        const response = await api.getCurrencies();
        if (response.data && response.data.data) {
          commit('setCurrencies', response.data.data);
        }
      } catch (error) {
        commit('setCurrencies', ['PLN']);
      }
    },
    async fetchUserEvents({ commit, rootState }) {
      commit('setLoading', true);
      commit('setError', false);
      try {
        const userId = rootState.auth.user.id;

        const events = await api.getUserEvents(userId);
        commit('setEvents', events.data.data);
      } catch (error) {
        commit('setEvents', []);
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    async fetchEventById({ commit, state, dispatch }, { eventId }) {
      commit('setLoading', true);
      commit("setError", false);
      try {
        const currency = state.currency;

        const currentEvent = await api.getEventDetails(eventId, currency);
        const event = state.events.find((event) => event.id === parseInt(eventId));

        const combinedEventData = {
          ...event,
          ...currentEvent.data.data,
        };

        commit('setCurrentEvent', combinedEventData);

        dispatch('fetchTotalPhotosInEvent', { wpRunId: eventId });

        return combinedEventData
      } catch (error) {
        commit("setError", true);
        commit('setCurrentEvent', {});
      } finally {
        commit('setLoading', false);
      }
    },
    toggleSidebar({ commit }, isOpen) {
      commit('setSidebar', isOpen);
    },
    async fetchTotalPhotosInEvent({ commit }, { wpRunId }) {
      commit('setLoading', true);
      commit("setError", false);

      try {
        const response = await api.getTotalPhotosInEvent(parseInt(wpRunId));
        commit('setTotalPhotosInEvent', response.data.data);
      } catch (error) {
        commit("setError", true);
        commit('setTotalPhotosInEvent', 0);
      } finally {
        commit('setLoading', false);
      }
    },
    async fetchEventOrdersData({ commit, state }, { eventId, page, perPage }) {
      commit('setOrdersLoading', true);
      commit('setOrdersError', null);

      try {
        const response = await api.getEventOrdersTableData(eventId, page, perPage, state.currency);
        const responseData = response.data.data;

        const ordersData = responseData.orders || {};

        const totalTableItemsCount = responseData.totalItems || 0;
        const totalPages = responseData.totalPages || 0;
        const currentPage = responseData.currentPage || 1;

        commit('setOrdersData', Object.values(ordersData));
        commit('setTotalTableItemsCount', totalTableItemsCount);
        commit('setTotalOrganizatorCommission', responseData.totalOrganizatorCommission);
        commit('setOrganizatorCommissionPerCurrency', responseData.organizatorCommissionPerCurrency);
        commit('setPagination', { totalPages, currentPage });
      } catch (error) {
        commit('setOrdersError', error.message);
      } finally {
        commit('setOrdersLoading', false);
      }
    },
    setCurrency({ commit }, currency) {
      commit('setCurrency', currency);
    },
    async createEventVerification({ commit, dispatch }, eventData) {
      commit('setLoading', true);
      commit('setError', false);

      try {
        const response = await api.createEventVerification(eventData);
        const newUnverifiedEvent = response.data.data;

        await dispatch('fetchUserUnverifiedEvents');
        await dispatch('fetchUserEvents');

        return newUnverifiedEvent;
      } catch (error) {
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    async fetchUserUnverifiedEvents({ commit, rootState }) {
      commit('setLoading', true);
      commit('setError', false);

      try {
        const userId = rootState.auth.user.id;

        const response = await api.getUserEventVerifications(userId);
        if (response.data && Array.isArray(response.data.data)) {
          const formattedUnverifiedEvents = response.data.data.map((event) => ({
            id: event.eventId,
            name: event.eventTitle,
            startDate: event.eventDate,
            eventStatus: 'unverified',
            thumbnail: event.landingImage,
            postAuthor: event.organizerId,
          }));
          commit('setUnverifiedEvents', formattedUnverifiedEvents);
        }
      } catch (error) {
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    async deleteEventVerification({ commit, state }, id) {
      commit('setLoading', true);
      commit('setError', false);

      try {
        const response = await api.deleteEventVerification(id);

        const updatedUnverifiedEvents = state.unverifiedEvents.filter(event => event.id !== id);
        commit('setUnverifiedEvents', updatedUnverifiedEvents);
        return response.data.data;
      } catch (error) {
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    async getEventFormData({ commit }, { action, eventId }) {
      commit('setLoading', true);
      commit('setError', false);

      try {
        let response;
        if (action === 'verification') {
          response = await api.getSingleEventVerification(eventId);
        } else if (action === 'event') {
          response = await api.getSingleEvent(eventId);
        }

        return response.data.data;
      } catch (error) {
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    async updateEvent({ commit, dispatch }, { action, eventId, eventData }) {
      commit('setLoading', true);
      commit('setError', false);

      try {
        let response;
        if (action === 'verification') {
          response = await api.updateEventVerification(eventId, eventData);
        } else if (action === 'event') {
          response = await api.updateEvent(eventId, eventData);
        }

        await dispatch('fetchUserUnverifiedEvents');
        await dispatch('fetchUserEvents');

        return response.data.data;
      } catch (error) {
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setEvents(state, events) {
      state.events = events || [];
      localStorage.setItem('events', JSON.stringify(state.events));
    },
    setUnverifiedEvents(state, unverifiedEvents) {
      state.unverifiedEvents = unverifiedEvents || [];
      localStorage.setItem('unverifiedEvents', JSON.stringify(state.unverifiedEvents));
    },
    setCurrentEvent(state, currentEvent) {
      state.currentEvent = currentEvent;
      localStorage.setItem('currentEvent', JSON.stringify(currentEvent));
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setSidebar(state, isOpen) {
      state.isSidebarOpen = isOpen;
    },
    setOrdersData(state, data) {
      state.ordersData = data;
    },
    setOrdersLoading(state, loading) {
      state.ordersLoading = loading;
    },
    setOrdersError(state, error) {
      state.ordersError = error;
    },
    setTotalTableItemsCount(state, itemsCount) {
      state.ordersTableItemCount = itemsCount;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    setTotalOrganizatorCommission(state, totalOrganizatorCommission) {
      state.totalOrganizatorCommission = totalOrganizatorCommission;
    },
    setOrganizatorCommissionPerCurrency(state, organizatorCommissionPerCurrency) {
      state.organizatorCommissionPerCurrency = organizatorCommissionPerCurrency;
    },
    setTotalPhotosInEvent(state, data) {
      state.totalPhotosInEvent = data;
    },
  },
};
