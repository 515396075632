export default {
  ERR_NETWORK: 'Sorry, there was a network problem, please try again later ..',
  ERR_BAD_RESPONSE:
    'Invalid server response. Sorry, please try again, if unsuccessful, please come back in some time.',
  login: {
    loginTitle: 'Login',
    emailInfo:
      'If you want to get commission for uploaded photos, please enter the email address associated with your paypal account',
    email: 'Email',
    phoneNumberInfo:
      'If you want to payout money, please enter correct phone number',
    phoneNumber: 'Phone number',
    password: 'Password',
    repassword: 'Repeat password',
    forgotPassword: 'Do not you remember the password?',
    loginButton: 'Sign in',
    loginNotify: 'Logged in!',
    register: 'Registration',
    username: 'User name',
    registration: 'Register',
    createAccount: 'Create an account',
    referal: 'Add referal',
    referalTitle: 'Add a referring runner',
    referalDesc:
      "(optional) You can enter the STARTING number of your friends runner - for every 50 pictures uploaded by the number's friends, they get a 5% discount!",
    runnerNumber: 'Runner number',
    accountType: 'Account type',
    supporter: 'Amateur photographer/bystander',
    photographer: 'Professional photographer (hired)',
    portfolioTitle:
      'I am a professional photographer, I want to receive payment for my photos. I am applying for verification - Link to my portfolio:',
    portfolio: 'Portfolio',
  },
  recoverPassword: {
    recoverDescription:
      'Please input an email used when registering account, we will send you a link to recover your password',
    recoverButton: 'Recover password',
    recoverGoBack: 'Hide password recovery',
    recoverSuccess:
      'If an account with this email exists, a recovery mail has been sent',
    recoverError: 'An error occured while sending a recovery mail',
    title: 'Set a new password',
    resetButton: 'Change password',
    repeat: 'Repeat password',
    resetInfo: 'Password strength requirements:',
    requirements1: 'min 8 characters',
    requirements2: 'min 1 uppercase',
    requirements3: 'min 1 lowercase',
    requirements4: 'min 1 number',
    resetSuccess: 'Password changed! You may now login.',
    invalidEmail: 'Incorrect email',
    invalidKey: 'Password reset key has expired, please request a new reset email.',
    validation: 'Password strength requirements not met.',
  },
  validation: {
    required: 'The field is required',
    minValue: 'The minimum number of characters is 3',
    email: 'Please enter a valid email',
    countryRequired: 'Select a country',
    phoneNumber: 'Please enter a valid phone number',
    samePassword: 'Passwords should be identical',
    completeAllFields: 'Complete all data',
    accountType: 'Account type is invalid',
    portfolio: 'Portfolio url is invalid',
    minPrice: "Price must be at least {min} zł",
    postcode: 'Invalid postcode format, must be 1-6 characters',
    language: 'Language must be selected',
    bankAccountIban:
      'IBAN must be between 15-34 characters and of valid format',
    bankAccountCurrency: 'Your bank account currency must be selected',
  },
  duplicates: {
    general:
      'Your recent photos that you uploaded are duplicates of photos that already exist in the system. The following events contain duplicates:',
    unknownEvent: 'Unknown run'
  },
  navigation: {
    home: 'Home',
    addPhoto: 'Photo',
    walletProfile: 'Wallet',
    myEvents: 'My events',
    logout: 'Sign out',
    profile: 'Your profile',
    signAs: 'Signed in as',
    signIn: 'Sign in',
    role: 'Role',
  },
  events: {
    description:
      "Events you are part of are displayed below, click on the button to go to individual event's dashboard",
    eventStart: 'Event date',
    albumsQty: 'Albums',
    button: 'Go to dashboard',
    loading: 'Loading your events...',
    noEventsMessage: 'You are not part of any events.',
    noOtherEvents: 'No other events',
    eventStatus: 'Event status',
    verified: 'Verified',
    unverified: 'Unverified',
    addEvent: 'Add event',
    editEvent: 'Edit event information',
    deleteBtn: 'Delete event',
    deleted: 'Event draft deleted',
    form: {
      eventTitle: 'Event name',
      photoPrice: 'Single photo price (PLN)',
      albumPrice: 'Album price (PLN)',
      image: 'Event page image (max. 5mb)',
      submit: 'Submit for verification',
      authorsCommission: 'Professional photo author\'s commission (%)',
      hasBib: 'There will be a bib number',
      error: 'Error while submitting a form, please check all fields.',
      success: 'Event successfully created!',
      fileFormat: 'Wrong file format. jpeg, jpg, png formats are allowed.',
      fileSize: 'File size too big',
      upload: 'Error uploading this file, please choose another image.',
      update: 'Update event',
      updateSuccess: 'Event updated successfully!',
      fetchError: 'Error while getting event information, try again.'
    },
    error: {
      notFound: 'Event not found',
      update: 'Error while updating event. Please try again.',
      add: 'Error while adding event. Please try again.'
    },
    verifiedTab: 'Approved events',
    unverifiedTab: 'Awaiting approval',
  },
  dashboard: {
    menu: {
      shares: 'Shares',
      totalOrders: 'Orders',
      backBtn: 'Back to events',
      stats: 'Statistics',
      addPhoto: 'Add photos',
      prefillForm: 'Form has been prefilled with event data',
      showMore: 'Show more',
      showLess: 'Hide',
      currencyBtn: 'Select a currency',
      currencyInfo: 'Showing data for currency'
    },
    main: {
      salesReport: 'Sales report',
      earnedCommision: 'Earned commission',
      comissionPayout: 'Available to withdraw',
      withdraw: 'Withdraw',
      albumViews: 'Album views',
      albumPhotos: 'Photos in a event',
      photosSold: 'Purchased photos',
      startDate: 'Event date',
      totalOrders: 'Total orders',
      totalAlbumSalesAmount: 'Album sales',
      socialMediaSharesCount: 'Shares',
      totalCommissionEarned: 'Commission earned',
      totalCommissionPaid: 'Total withdrawn',
      amountToBePaid: 'Available to withdraw',
      totalAlbumViews: 'Total album views',
      totalPhotosInEvent: 'Total photos in event',
      totalAlbumsSold: 'Albums sold',
      totalPhotosSold: 'Single sold photos',
      loading: 'Loading event data...',
    },
    ordersTable: {
      title: 'Orders completed',
      sumCommissions: 'Total commissions',
      orderId: 'Order no.',
      orderDate: 'Transaction date',
      albumLink: 'Album link',
      orderAmount: 'Base price',
      coupon: 'Coupon(%)',
      currency: 'Currency',
      authorCommission: 'Photo author commission',
      paygateCommission: 'Payment operator fees',
      runpixieCommission: 'Runpixie fees',
      afterFeesTotal: 'Earned total',
      noOrders: 'No orders'
    },
    sharesTable: {
      title: 'Shares table',
      shareId: 'ID',
      link: 'Link',
      copy: 'Copy',
      linkCopySuccess: 'Link copied to clipboard!',
      linkCopyFail: 'Failed to copy link',
      noData: 'No shared albums on record'
    },
    charts: {
      day: 'Last 24 hours',
      week: 'Last 7 days',
      month: 'Last 30 days',
      total: 'Total',
      timeFrame: 'Time period',
      weekLabel: 'Week'
    }
  },
  role: {
    photographer: 'Photographer',
    supporter: 'Supporter',
    organizer: 'Organizer',
    sponsor: 'Sponsor',
    photographer_verification: 'Photographer (verification)',
    agent: 'Agent',
  },
  addPhoto: {
    title: 'Add photos',
    blockedText: 'To add photos log in',
    subHeaderTitle: 'RunPixie - Find Yourself!',
    agreement:
      'By uploading photos, you accept the Terms, conditions of use, returns and cancellations policy, licence agreement',
  },
  common: {
    clear: 'Clear',
    add: 'Add',
    subHeaderTitle: 'RunPixie - Find Yourself!',
    country: 'Country',
    completeData: 'Complete data',
    city: 'City',
    selectDate: 'Select date',
    close: 'Close',
    submit: 'Submit',
    selectedDate: 'Selected date:',
    gear: 'Event',
    summary: 'Summary',
    imagesCount: 'Selected images',
    date: 'Date',
    next: 'Next',
    return: 'Back',
    ups: 'Something went wrong!',
    turnOnThumbs: 'Enable thumbnails in the preview',
    turnOnThumbsInfo:
      'Enable this option if you want to see thumbnails in the preview (not recommended when adding more photos)',
    statuteRoute: '/en/license-agreement/',
    checkHere: 'check here',
    understand: 'I understand',
  },
  userPanel: {
    title: 'User panel',
    hello: 'Hello',
    addedPhoto: 'Added photos',
    inSystem: 'In system from',
    country: 'Country',
    city: 'City',
    gear: 'Event',
    emailChanges: 'Email was changed',
    badEmail: 'Please enter a valid email',
    walletRequestsTitle: 'Registered withdrawal requests',
    walletCommissionsTitle: 'Registered commissions',
    blockedMoneyInfo: 'Value of your current withdrawal requests (blocked)',
    noPersonalDataInfo:
      'To create withdrawal proposal, please fill your personal data: first name, last name, phone number and address',
    noWithdrawIntegration:
      'To create withdrawal proposal, Your account must be integrated with the payment partner',
    withdrawMoney: 'Dispose of funds',
    minWithdrawValue: 'Minimum Withdrawal Amount',
    withdraw: 'Withdraw',
    wallet: 'Wallet',
    ownCommisionsUnpaid: 'The sum of unpaid commissions',
    ownCommisionsAll: 'The sum of all commissions earned',
    albumName: 'Album name',
    commission: 'Commission',
    status: 'Status',
    sum: 'Sum',
    yourData: 'Your data',
    phoneNumber: 'Nr.',
    paymentInfo:
      'Data necessary to make withdrawals - if after the change, you can still see the old data, log out and log in again.',
    paid: 'Paid',
    tableWalletHint:
      'Below is a list of albums with your photos that someone has bought. Remember that the amount of the commission obtained is (a) the possible bonus for the best photo in the package and (b) the amount of the commission to be divided between the authors proportionally according to the number of photos.',
    tableWalletHintOther:
      'Below is a list of commissions earned for purchased albums and photographs. Each row relates to order in which commission was earned.',
    tableColumns: {
      album: 'Number of the album sold',
      gear: 'Event',
      commission: 'The amount of commission received',
      date: 'Data',
      commissionData: 'Date of the commission payout',
      name: 'Name',
    },
    tableTitle: 'Commissions earned',
    imagesTableTitle: 'List of your photos within the album',
    promptWalletInfo:
      'You pay a commission for the license granted to RunPixie for photos that have been purchased by runners, in accordance with the website regulations and the license agreement',
    promptWalletInfo2:
      'Recommended payment above PLN 20, required after exceeding PLN 500. The cost of the payment is PLN 2.5. Funds not disbursed within 2 years are forfeited',
    regulamin: 'terms and conditions of the website and the license agreement',
    accountData: 'Account details:',
    billingAddress: 'Billing address',
    userData: {
      name: 'First name',
      surname: 'Last name',
      nrTelephone: 'Phone',
      adress: 'Address',
      address2: 'Address 2',
      state: 'State/Province',
      company: 'Company',
      country: 'Country',
      city: 'City',
      postcode: 'Postcode',
      email: 'Email',
      role: 'Role',
      submit: 'Update data',
    },
    empty: 'Not completed',
    completeData:
      'Complete data (you will be redirected to a new tab [you will need to log in again])',
    editData: 'Edit data',
    solded: 'Sold',
    withdrawNotConnectedInfo:
      'No connection with the external withdraw platform',
    connectHereLabel: 'Connect here',
    billingDataUpdating: 'Updating...',
    integration: {
      title: 'Withdrawal integration',
      bankAccountIban: 'Bank account IBAN',
      bankAccountCurrency: 'Bank account currency',
      language: 'Language',
      submit: 'Create/Sync Useme account',
      taxId: 'Tax Id',
      userExists:
        'Found account matching provided details on Useme. Integration successful. Please login again.',
      integrationValidation:
        'Account validation error, make sure fields Tax Id and IBAN are correct. If you already have Useme account, all details need to match your Useme profile.',
      editInformation:
        'If you would like to edit any of the details in future, you can do it via https://useme.com official site',
      informationTitle: 'Information regarding Useme integration',
      description:
        'In order to earn money for your photographs, you need to have Useme account. Useme is a third-party payments provider, that makes it easy for us to pay you, and for you to receive the payments. Registration is free and easy.',
      registerWays: 'You can register with Useme in 2 ways:',
      recommendedOption: 'Recommended option',
      optionTwo:
        'If you choose to register via official Useme site, you need to come back and use the same details in our form to complete the integration. Please note the details need to be identical to the ones on your Useme profile.',
      optionOne:
        'If you decide to register via our form, after successful registration, you can obtain your initial password by sending a reset password link ',
      optionOneAdditional:
        'You can then use this password to login to your Useme account and change the details as you like.',
      important: 'Important',
      termsAcceptance:
        'By registering with Useme, you accept their Terms & Conditions.',
      moreInfo: 'For more information visit',
      successTitle: 'Useme withdrawals integration completed!',
      successMessage: 'You have successfully integrated your Useme account.',
      emailLabel: 'Useme account email:',
      approvalMessage:
        'Once our staff approves you as a professional photographer, you will be ready to start earning money for your photos.',
      payoutDetails:
        'Please remember if you would like to change any of your payout details, you can do so via Useme portal available after logging in on their ',
      resetPasswordMessage:
        'If during integration, we have created an account for you on Useme, you can login to their portal and reset password',
      resetPasswordLink: 'here.',
      officialSite: 'official site',
    },
  },
  status: {
    waiting: 'Waiting',
    rejected: 'Rejected',
    completed: 'Completed',
  },
  error: {
    badAuth: 'Bad authorization.',
    badAuthToken: 'Bad authorization - theres no token.',
    badAuthPriviliges: 'Bad authorization - you need higher privileges.',
    badAuthTokenProblem: 'Bad authorization - problem with token.',
    route: 'You are not allowed to use this route.',
    walletNoAmmount: 'No amount or author or ballance fields',
    code: 'Something went wrong with code.',
    imageFail: 'Houston, we have got problem with upload image function',
    usernameRequired: 'Username field is required.',
    usernameExists: 'Username already exists.',
    emailRequired: 'Email field is required.',
    passwordRequired: 'Password field is required.',
    invalidCredentials: 'Invalid username or password.',
    emailExists: 'Email already exists, please try Reset Password.',
    phoneNumber: 'Phone number field is required.',
    phoneNumberExists: 'Phone number already exists.',
    addedImagesInfo: 'added images:',
    renewSendImages: 'Resume sending photos',
    sendLater: 'Close, i will send later',
    sendLaterInfo:
      'The photos from the first step have been updated, only those that have not been uploaded are left',
    catchedError: 'Error occured',
    registerWithdrawError: 'Withdraw registration error occured',
    errorBillingForm: 'Error while updating data, please check all fields.',
    fetchEvents:
      'Something went wrong while fetching events. Please try again later.',
    noDashboardData: 'Not enough data to populate. Please try again later.',
    invalidEvent: 'This event does not exist or you do not have access to it.',
    eventUnverified: 'This event has not been verified by administrator.',
    uploadsRestriction: 'Due to high traffic we have enabled upload restrictions, only verified photographers can currently upload photos. Please check again later.'
  },
  success: {
    registeredWithReq: 'A withdrawal request has been registered, thank you.',
    imageAdded: 'All, good. Images added, thank you!',
    accountHasBeenCreated: 'Account has been created successfully!',
    accountHasBeenCreatedPhotographerVerify:
      'Account has been created successfully! Type of your account is verifying.',
    billingDataUpdated: 'Billing data updated successfully!',
    integration: 'Integration successful, please login again.',
  },
  languages: {
    polish: 'Polish',
    english: 'English',
  },
};
