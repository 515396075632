<template>
  <div class="user-panel">
    <WalletComponent
      class="wallet-wrapper"
      :walletMoney="parseFloat(wallet)"
      :walletSumOfAllTime="parseFloat(walletSumOfAllTime)"
      :registeredCommissions="registeredCommissions"
      :withdrawRequestsList="withdrawRequests"
      :withdrawalRequestsSumAmmount="withdrawalRequestsSumAmmount"
    />
    <div class="user-panel__tabs-wrapper">
      <q-tabs
        v-model="tab"
        :vertical="isMobile ? false : true"
        class="text-white"
        align="justify"
      >
        <q-tab name="settings" icon="settings" :label="$t('userPanel.title')" />
        <q-tab
          name="images"
          icon="images"
          :label="$t('userPanel.addedPhoto')"
        />
        <q-tab
          name="integration"
          icon="cable"
          :label="$t('userPanel.integration.title')"
        />
      </q-tabs>

      <q-tab-panels
        keep-alive
        v-model="tab"
        animated
        :vertical="isMobile ? false : true"
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <q-tab-panel name="settings">
          <UserCard />
        </q-tab-panel>

        <q-tab-panel name="images" class="q-px-xs">
          <div class="text-h4 q-mb-lg q-pb-md text-center user-panel__title">
            {{ $t("userPanel.addedPhoto") }}
          </div>
          <ImagesTable />
        </q-tab-panel>
        <q-tab-panel name="integration" class="q-px-sm">
          <div class="text-h4 q-py-lg text-center user-panel__title">
            {{ $t("userPanel.integration.title") }}
          </div>
          <IntegrationCard />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script>
import ImagesTable from "./components/ImagesTable.vue";
import UserCard from "./components/UserCard.vue";
import WalletComponent from "./components/WalletComponent.vue";
import IntegrationCard from "./components/IntegrationCard.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "UserPanelPage",
  components: {
    ImagesTable,
    UserCard,
    WalletComponent,
    IntegrationCard,
  },
  props: {
    defaultTab: {
      type: String,
      default: "settings",
    },
  },
  data() {
    return {
      tab: this.defaultTab,
      splitterModel: 20,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      wallet: (state) => state.global.wallet,
      walletSumOfAllTime: (state) => state.global.walletSumOfAllTime,
      registeredCommissions: (state) => state.global.registeredCommissions,
      withdrawRequests: (state) => state.global.withdrawRequests,
      withdrawalRequestsSumAmmount: (state) =>
        state.global.withdrawalRequestsSumAmmount,
    }),
    isMobile() {
      return this.$q.screen.width < 780;
    },
  },
  watch: {
    tab(newTab) {
      this.$router
        .push({
          query: { tab: newTab },
        })
        .catch(() => {});
    },
    "$route.query.tab"(newTab) {
      if (newTab && ["settings", "images", "integration"].includes(newTab)) {
        this.tab = newTab;
      }
    },
  },
  methods: {
    ...mapActions({
      getUserWalletData: "global/getUserWalletData",
      getWithdrawRequestList: "global/getWithdrawRequestList",
      getAddedPhotos: "global/getAddedPhotos",
    }),
  },
  beforeMount() {
    this.getAddedPhotos({ page: 1 });
    this.getUserWalletData();
    this.getWithdrawRequestList();
  },
};
</script>

<style lang="scss" scoped>
.wallet-wrapper {
  z-index: 10;
  width: 100%;
}
.user-panel {
  padding-top: 150px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  &__title {
    border-bottom: 1px solid $accent;
  }
  &__tabs-wrapper {
    display: flex;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    :deep .q-tab__content > .q-icon {
      display: block;
    }
  }
}
.q-tabs {
  width: 20%;
}
.q-tab-panels {
  width: calc(100% - 20% - 15px);
  margin-right: 15px;
}
@media (max-width: 780px) {
  .q-tabs {
    width: 100%;
  }
  .q-tab-panels {
    width: 100%;
    margin-right: 0;
  }
}
</style>
