<template>
  <div class="user-form__wrapper">
    <div v-if="isLoading">
      <q-skeleton type="rect" class="q-mb-md" height="56px" />
      <q-skeleton type="rect" class="q-mb-md" height="56px" />
      <q-skeleton type="rect" class="q-mb-md" height="56px" />
      <q-skeleton type="rect" class="q-mb-md" height="56px" />
      <q-skeleton type="rect" class="q-mb-md" height="56px" />
      <q-skeleton type="rect" class="q-mb-md" height="56px" />
    </div>
    <q-form v-if="!isLoading" @submit="onSubmit" class="q-gutter-sm">
      <q-input
        v-model="form.billing_first_name"
        class="q-pb-xs"
        :label="$t('userPanel.userData.name')"
        type="text"
        lazy-rules
        :rules="[required, short]"
      />
      <q-input
        v-model="form.billing_last_name"
        class="q-pb-xs"
        :label="$t('userPanel.userData.surname')"
        type="text"
        lazy-rules
        :rules="[required, short]"
      />
      <q-input v-model="form.billing_company"
        class="q-pb-xs"
        :label="$t('userPanel.userData.company')"
       />
      <q-input
        v-model="form.billing_email"
        class="q-pb-xs"
        :label="$t('userPanel.userData.email')"
        type="email"
        lazy-rules
        :rules="[required, isEmail, short]"
      />
      <q-select
        v-model="form.billing_country"
        class="q-pb-xs"
        :options="countryOptions"
        @update:model-value="updateCountry"
        :label="$t('userPanel.userData.country')"
        lazy-rules
        :rules="[isOptionSelected]"
      />
      <q-select
        v-if="currentStates && currentStates.length > 0"
        v-model="form.billing_state"
        :options="currentStates"
        lazy-rules
        :label="$t('userPanel.userData.state')"
      />
      <q-input
        v-model="form.adress"
        class="q-pb-xs"
        :label="$t('userPanel.userData.adress')"
        lazy-rules
        :rules="[required, short]"
      />
      <q-input
        v-model="form.address_2"
        class="q-pb-xs"
        :label="$t('userPanel.userData.address2')"
       />
      <q-input v-model="form.city"
        class="q-pb-xs"
       :label="$t('userPanel.userData.city')"
       :rules="[short, required]" />
      <q-input
        v-model="form.postcode"
        class="q-pb-xs"
        :label="$t('userPanel.userData.postcode')"
        lazy-rules
        :rules="[required]"
      />
      <q-input
        v-model="form.phone_number"
        :label="$t('userPanel.userData.nrTelephone')"
        lazy-rules
        :rules="[required, isPhoneNumber]"
      />
      <q-btn 
       :label="$t('userPanel.userData.submit')"
       type="submit" color="primary" />
    </q-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api/api.js";
import { validationMethods as validate } from "@/services/validation.service";

export default {
  name: "UserData",
  data() {
    return {
      form: {
        billing_first_name: "",
        billing_last_name: "",
        billing_company: "",
        billing_email: "",
        billing_state: "",
        billing_country: "",
        adress: "",
        address_2: "",
        city: "",
        postcode: "",
        phone_number: "",
      },
      currentCountry: null,
      currentStates: null,
      countries: null,
      states: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    countryOptions() {
      if (!this.countries) {
        return [];
      }
      return Object.entries(this.countries).map(([isoCode, name]) => ({
        label: name,
        value: isoCode,
      }));
    },
  },
  watch: {
    "form.billing_country": {
      handler(newValue) {
        if (newValue) {
          this.form.billing_state = null;
          this.updateStates(newValue.value);
        }
      },
    },
  },
  methods: {
    async fetchCountries() {
      this.isLoading = true;
      try {
        const response = await api.getCountries();
        this.countries = response.data.countries;
        this.states = response.data.states;
      } catch (error) {
        this.$q.notify({
          icon: "warning",
          spinner: true,
          message: this.$t('ERR_NETWORK'),
          timeout: 2000,
        });
        console.error("Error fetching countries:", error);
      } finally {
        this.isLoading = false;
      }
    },
    updateCountry(newCountry) {
      this.currentCountry = newCountry.value;
      this.updateStates(newCountry.value);
    },
    updateStates(countryCode) {
      const statesForCountry = this.states[countryCode];
      if (statesForCountry && Object.keys(statesForCountry).length > 0) {
        this.currentStates = Object.entries(statesForCountry).map(
          ([isoCode, name]) => ({
            label: name,
            value: isoCode,
          })
        );
      } else {
        this.currentStates = null;
      }
    },
    // submit form
    onSubmit() {
      const loadingNotification = this.showLoadingNotification();
      const formData = this.prepareFormData();

      const userId = this.user.id;
      this.$store
        .dispatch("auth/updateBilling", { formData, userId })
        .then(() => {
          this.showSuccessNotification(loadingNotification);
          this.$router.push("/");
        })
        .catch((error) => {
          this.handleSubmissionError(error);
        });
    },
    prepareFormData() {
      const formData = { ...this.form };
      if (formData.billing_country && formData.billing_country.value) {
        formData.billing_country = formData.billing_country.value;
      }
      if (formData.billing_state && formData.billing_state.value) {
        formData.billing_state = formData.billing_state.value;
      }
      return formData;
    },
    initializeForm() {
      Object.keys(this.form).forEach((key) => {
        if (this.user[key]) {
          if (key === "billing_country") {
            this.form[key] =
              this.countryOptions.find(
                (country) => country.value === this.user[key]
              ) || null;
            if (this.form[key]) {
              this.updateStates(this.form[key].value);
            }
          } else {
            this.form[key] = this.user[key];
          }
        }
      });
    },
    showLoadingNotification() {
      return this.$q.notify({
        spinner: true,
        message: this.$t('userPanel.billingDataUpdating'),
        timeout: 2000,
      });
    },
    showSuccessNotification(loadingNotification) {
      this.$q.notify({
        id: loadingNotification,
        icon: "check",
        color: "positive",
        message: this.$t('success.billingDataUpdated'),
        timeout: 2000,
      });
    },

    handleSubmissionError(error) {
      console.error(error);
      this.$q.notify({
        icon: "error",
        color: "negative",
        message: this.$t('error.billingForm'),
        timeout: 2000,
      });
    },
    // validation rules
    required(val) {
      return validate.required(val, this.$t);
    },
    isOptionSelected(val) {
      return validate.isOptionSelected(val, this.$t);
    },
    short(val) {
      return validate.short(val, this.$t);
    },
    isEmail(val) {
      return validate.isEmail(val, this.$t);
    },
    isPhoneNumber(val) {
      return validate.isPhoneNumber(val, this.$t);
    },
  },
  async created() {
    await this.fetchCountries();
    this.initializeForm();
    this.$nextTick(() => {
      if (this.user.billing_state && this.currentStates) {
        this.form.billing_state =
          this.currentStates.find(
            (state) => state.value === this.user.billing_state
          ) || null;
      }
    });
  },
};
</script>

<style>
.user-form__wrapper {
  margin-top: 12px;
  max-width: 350px;
}

</style>
