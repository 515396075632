<template>
  <div>
    <q-separator color="primary" />
    <q-toolbar>
      <q-toolbar-title class="sidebar-title">
        {{ currentEvent.name }}
      </q-toolbar-title>
    </q-toolbar>
    <q-item class="sidebar-action">
      <q-item-label caption>{{ currentEvent.startDate }}</q-item-label>
      <q-btn
        v-if="!isSidebarOpen"
        class="sidebar-drawer__button"
        flat
        @click="closeSidebar"
        round
        dense
      >
        <q-icon name="west" />
        <q-icon name="space_dashboard" />
      </q-btn>
    </q-item>
    <q-separator color="primary" />
    <q-item
      v-ripple
      clickable
      class="sidebar-events__item btn"
      @click="goToUpload()"
    >
      <q-item-section side top>
        <q-icon color="primary" name="add_circle" />
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ $t("dashboard.menu.addPhoto") }}</q-item-label>
      </q-item-section>
    </q-item>
    <q-item-label header class="sidebar-events__header">{{
      $t("dashboard.menu.stats")
    }}</q-item-label>

    <q-item v-for="link in links" :key="link.text" v-ripple class="q-mt-sm">
      <q-item-section side top>
        <q-icon color="primary" :name="link.icon" />
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ $t(link.text) }}</q-item-label>
        <q-item-label caption>{{ eventCaptions[link.text] }}</q-item-label>
      </q-item-section>
    </q-item>

    <q-list bordered padding class="sidebar-events">
      <q-item-label header class="sidebar-events__header">
        {{ $t("navigation.myEvents") }}
      </q-item-label>
      <template v-if="eventsFiltered.length > 0">
        <q-item
          v-for="event in displayedEvents"
          :key="event.id"
          v-ripple
          clickable
          class="sidebar-events__item"
          @click="goToEvent(event.id)"
        >
          <q-item-section side top class="q-px-xs">
            <q-icon color="primary" name="sports_score" />
          </q-item-section>
          <q-item-section>
            <q-item-label class="text-italic">{{ event.name }}</q-item-label>
            <div class="sidebar-events__details">
              <q-item-label caption>{{ event.startDate }} </q-item-label>
              <q-item-label caption>
                {{ event.city.name }}
              </q-item-label>
            </div>
          </q-item-section>
        </q-item>
        <q-item v-if="eventsFiltered.length > 2">
          <q-btn
            flat
            @click="toggleShowMore"
            class="q-px-xs absolute-center"
            color="primary"
          >
            {{
              showMore
                ? $t("dashboard.menu.showLess")
                : $t("dashboard.menu.showMore")
            }}
          </q-btn>
        </q-item>
      </template>
      <q-item v-else class="sidebar-events__item">
        <q-item-section>
          <q-item-label>{{ $t("events.noOtherEvents") }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SideBar",
  data() {
    return {
      showMore: false,
    };
  },
  props: {
    event: {
      type: Object,
    },
  },
  computed: {
    ...mapState("global", ["isSidebarOpen"]),
    ...mapState("dashboardEvents", [
      "currentEvent",
      "links",
      "events",
      "loading",
      "ordersTableItemCount",
      "ordersLoading",
    ]),
    eventsFiltered() {
      return this.events.filter((event) => event.id !== this.currentEvent.id);
    },
    displayedEvents() {
      return this.showMore
        ? this.eventsFiltered
        : this.eventsFiltered.slice(0, 3);
    },
    eventCaptions() {
      return {
        "dashboard.menu.shares": this.currentEvent.sharedAlbumsCount || 0,
        "dashboard.main.totalOrders": this.ordersLoading
          ? 0
          : this.ordersTableItemCount || 0,
      };
    },
  },
  methods: {
    ...mapActions("global", ["saveFormData"]),
    ...mapActions("dashboardEvents", ["toggleSidebar"]),
    goToEvent(id) {
      this.$router.push(`/my-events/${id}`);
    },
    goToUpload() {
      const formData = {
        date: this.currentEvent.startDate,
        city: this.currentEvent.city?.id,
        country: this.currentEvent.country?.id,
        gear: this.currentEvent.id,
      };
      this.saveFormData(formData);

      this.$router.push({
        name: "addPhoto",
        query: { eventId: this.currentEvent.id },
      });
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    closeSidebar() {
      this.toggleSidebar(false);
    },
    openSidebar() {
      this.toggleSidebar(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  &-action {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
  }
  &-title {
    text-wrap: wrap;
  }
  &-drawer__button {
    width: 80px;
  }
  &-events {
    &__header {
      background: rgba($color: $primary, $alpha: 0.075);
      padding: 0.75rem;
      font-weight: bold;
      font-size: 16px;
    }
    &__item {
      padding: 1rem 0.5rem;
    }
    &__details {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-content: center;

      > div {
        margin: 0;
      }
    }
  }
}
</style>