<template>
  <div class="user-card__wrapper">
    <q-avatar class="user-card__user-avatar">
      <img :src="require('@/assets/userDefault.png')" />
    </q-avatar>
    <div class="user-card__block">
      <span class="text-h4 title q-pb-sm">{{
        `${$t("userPanel.hello")} ${user.user_display_name}`
      }}</span>
      <div class="q-mt-lg">
      <q-chip dense v-if="!isLoading">
        <q-avatar color="red" text-color="white">
          {{ addedPhotosCount || 0 }}
        </q-avatar>
        <span class="q-ml-sm">{{ $t("userPanel.addedPhoto") }}</span>
      </q-chip>
      <q-spinner v-else />
        <q-chip dense icon="event"
          >{{ $t("userPanel.inSystem") }} {{ setDate }}</q-chip
        >
      </div>
      <h3 class="text-h5 q-mb-sm">{{ $t('userPanel.accountData') }}</h3>
      <div class="email-block">
        <q-input
          v-if="isEditMode"
          ref="email"
          v-model="email"
          dense
          square
          hide-bottom-space
          type="email"
          lazy-rules
          :rules="[isEmail, short, required]"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>
        <span v-else class="text-body2">
          {{ user.user_email }}
        </span>
        <q-btn
          round
          dense
          :color="isEditMode ? 'positive' : 'accent'"
          :flat="!isEditMode"
          :icon="isEditMode ? 'check' : 'edit'"
          class="q-ml-sm"
          @click="editMode"
        />
      </div>
      <q-separator spaced />
      <h3 class="text-h5 q-mt-sm">{{ $t('userPanel.billingAddress') }}</h3>
      <UserData />

      <div class="user-card__information-block">
        <span class="text-caption q-mt-md text-weight-bold">
          ({{ $t("userPanel.paymentInfo") }})
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import UserData from "./UserData.vue";

export default {
  name: "UserCard",
  components: { UserData },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      addedPhotosCount: (state) => state.global.addedPhotosCount,
      isLoading: (state) => state.global.isLoading,
    }),
    setDate() {
      return moment(this.user.user_registered).format("DD-MM-YYYY");
    },
  },
  data() {
    return {
      isEditMode: false,
      email: "",
      backendRoot: process.env.VUE_APP_BACK_ROOT,
    };
  },
  mounted() {
    this.email = this.user?.user_email;
  },
  methods: {
    ...mapActions({ changeUserData: "auth/changeUserData" }),
    adressText(postCode, adress, city) {
      let text = "";
      if (!postCode || !adress || !city) {
        text = this.$t("userPanel.empty");
      } else {
        text = `${postCode}, ${adress}, ${city}`;
      }
      return text;
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    short(val) {
      return (val && val.length > 3) || this.$t("validation.minValue");
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || this.$t("userPanel.badEmail");
    },
    editMode() {
      if (this.isEditMode) {
        this.$refs.email.validate();
        if (this.email && !this.$refs.email.hasError) {
          if (this.email !== this.user.user_email) {
            try {
              api.editEmail(this.user.id, this.email).then((response) => {
                if (response.data.email) {
                  let user = JSON.parse(localStorage.getItem("user"));
                  user.user_email = response.data.email;
                  this.$q.notify({
                    icon: "done",
                    color: "positive",
                    message: this.$t("userPanel.emailChanges"),
                  });
                  localStorage.setItem("user", JSON.stringify(user));
                  this.changeUserData(user);
                }
              });
            } catch (error) {
              this.$q.notify({
                icon: "fail",
                color: "negative",
                message: this.$t("userPanel.badEmail"),
              });
            }
          }
        } else {
          this.$q.notify({
            icon: "info",
            color: "negative",
            message: this.$t("userPanel.badEmail"),
          });
        }
      }
      this.isEditMode = !this.isEditMode;
    },
  },
};
</script>

<style lang="scss" scoped>
.email-block {
  display: flex;
  align-items: center;
}
.user-card {
  &__user-avatar {
    width: 160px;
    height: 160px;
  }
  &__wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
  }
  &__block {
    border-left: 1px solid $accent;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    .title {
      border-bottom: 1px solid $accent;
    }
  }
  &__information-block {
    display: flex;
    flex-direction: column;
    span:first-child {
      font-weight: bold;
      max-width: 450px;
    }
  }

  &__payments-data {
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    border-right: 1px solid $accent;
    border-bottom: none;
  }
}
@media (max-width: 920px) {
  .user-card {
    &__information-block {
      flex-direction: column;
    }
    &__wrapper {
      flex-direction: column;
      align-items: center;
    }
    &__block {
      border-left: none;
      padding: 0 6px;
      margin: 0;
      width: 100%;
      max-width: 350px;
      .title {
        text-align: center;
        padding-bottom: 10px;
        margin: 10px 0;
      }
    }
    &__payments-data {
      border-bottom: 1px solid $accent;
      border-right: none;
    }
  }
}
@media (max-width: 548px) {
  .user-card {
    &__user-avatar {
      width: 80px;
      height: 80px;
      margin: auto;
    }
  }
}

@media (max-width: 360px) {
  .user-card {
    &__user-avatar {
      width: 55px;
      height: 55px;
      margin: auto;
    }
    &__information-block {
      flex-direction: column;
    }
  }
}

.connect-btn {
  max-width: 200px;
}
</style>
