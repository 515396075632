import axios from "axios";
import authHeader from "@/services/auth-header";
import apiHeader from "@/services/api-header";
import authHeaderQueue from "@/services/auth-header-queue";

export default {
  getUserInfo(userId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/${userId}`,
      headers: apiHeader(),
    })
  },
  integrateUseme(integrationData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/payouts/integrate`,
      headers: apiHeader(),
      data: {
        ...integrationData,
        platform: "useme",
      },
    });
  },
  userEdit(userData, userId) {
    return axios({
      method: "PUT",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/${userId}`,
      headers: apiHeader(),
      data: userData,
    });
  },
  getCountries() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/wc/v3/data/countries`,
      headers: authHeader(),
    });
  },
  userRegistration(userData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/register`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: userData,
    });
  },
  userLogin(userData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/login`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: userData,
    });
  },
  sendResetLink(email) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/reset-password/request`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: { email },
    });
  },
  resetPassword(email, key, newPassword, newPasswordConfirmation) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/reset-password/reset`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: { email, key, new_password: newPassword, new_password_confirmation: newPasswordConfirmation },
    });
  },
  // add-image select endpoints
  getSelectCities() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/wp/v2/cities`,
      headers: authHeader(),
    });
  },
  getSelectCountries() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/wp/v2/countries`,
      headers: authHeader(),
    });
  },
  getSelectGears() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/gear/all`,
      headers: authHeader(),
    });
  },
  sendPhotoData(data) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/photos/bulk`,
      headers: authHeaderQueue(),
      data: data,
    });
  },
  getDuplicates(userId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/photos/processed`,
      headers: authHeaderQueue(),
      params: {
        user_id: userId,
      },
    });
  },
  acceptDuplicates(userId) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/photos/processed`,
      headers: authHeaderQueue(),
      data: {
        user_id: userId,
      },
    });
  },
  getAddedPhoto(userId, page) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/photos/author`,
      headers: authHeaderQueue(),
      params: {
        wp_author_id: userId,
        page: page
      },
    });
  },
  editEmail(userId, email) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/wp/v2/users/${userId}`,
      headers: authHeader(),
      data: {
        email: email,
      },
    });
  },
  getNavbarOptions(lang) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/menu/main/${lang}`,
      headers: authHeader(),
    });
  },
  getUserWallet() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/wallet`,
      headers: authHeader(),
    });
  },
  withdrawUserPayment(withdrawalAmount, currentAccountBalance) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/wallet/withdrawal`,
      headers: authHeader(),
      data: {
        withdrawal_amount: withdrawalAmount,
        current_account_balance: currentAccountBalance,
      },
    });
  },
  getWithdrawRequests() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/wallet/requests`,
      headers: authHeader(),
    });
  },
  getLanguage() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/lang/current`,
    });
  },
  getCurrencies() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/currencies`,
      headers: apiHeader(),
    });
  },
  getToken(userData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/sanctum/token`,
      data: userData,
    });
  },
  getUserEvents() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/dashboard/races`,
      headers: apiHeader(),
    });
  },
  getEventViewsChartDataByTimeFrame(runId, timeFrame) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/dashboard/${runId}/views/timePeriod`,
      headers: authHeaderQueue(),
      params: {
        time_period: timeFrame
      },
    });
  },
  getEventChartDataByTimeFrame(runId, timeFrame, currency, type) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/dashboard/races/charts`,
      headers: apiHeader(),
      params: {
        raceId: runId,
        timeFrame: timeFrame,
        currency: currency,
        type: type
      },
    });
  },
  getEventOrdersTableData(runId, page, perPage, currency) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/dashboard/races/orders`,
      headers: apiHeader(),
      params: {
        page: page,
        perPage: perPage,
        raceId: runId,
        currency: currency
      },
    });
  },
  getEventDetails(runId, currency) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/dashboard/races/details`,
      headers: apiHeader(),
      params: {
        raceId: runId,
        currency: currency
      },
    });
  },
  getTotalPhotosInEvent(runId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_QUEUE_API}/api/v2/dashboard/${runId}`,
      headers: authHeaderQueue(),
    });
  },
  createEventVerification(eventData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/verifications`,
      headers: apiHeader(),
      data: eventData,
    });
  },
  updateEventVerification(eventId, eventData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/verifications/${eventId}`,
      headers: apiHeader(),
      data: eventData,
    });
  },
  getSingleEventVerification(eventId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/verifications/${eventId}`,
      headers: apiHeader(),
    });
  },
  getUserEventVerifications() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/verifications`,
      headers: apiHeader(),
    });
  },
  deleteEventVerification(eventId) {
    return axios({
      method: "DELETE",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/verifications/${eventId}`,
      headers: apiHeader(),
    });
  },
  getSingleEvent(eventId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/${eventId}`,
      headers: apiHeader(),
    });
  },
  updateEvent(eventId, eventData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/events/${eventId}`,
      headers: apiHeader(),
      data: eventData,
    });
  },
};
