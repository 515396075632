import { createWebHistory, createRouter } from "vue-router";
import LoginPage from "@/pages/login/LoginPage.vue";
import PasswordResetPage from "@/pages/passwordReset/PasswordResetPage.vue";
import AddPhotoPage from "@/pages/addPhoto/AddPhotoPage.vue";
import UserPanelPage from "@/pages/userPanel/UserPanelPage.vue";
import DashboardEventsListPage from '@/pages/dashboardEvents/dashboardEventsList/DashboardEventsListPage.vue';
import DashboardEventsSinglePage from '@/pages/dashboardEvents/dashboardEventsSingle/DashboardEventsSinglePage.vue';
import routerGuardService from '@/services/routerGuard.service';

const routes = [
  {
    path: '/',
    name: 'addPhoto',
    component: AddPhotoPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: PasswordResetPage,
    beforeEnter: routerGuardService.validatePasswordReset
  },
  {
    path: '/userPanel',
    name: 'userPanel',
    component: UserPanelPage,
    props: (route) => ({ defaultTab: route.query.tab }),
    beforeEnter: routerGuardService.handleTabSwitch
  },
  {
    path: '/my-events',
    name: 'dashboardEventsList',
    component: DashboardEventsListPage,
  },
  {
    path: '/my-events/:eventId',
    name: 'dashboardEventsSingle',
    component: DashboardEventsSinglePage,
    beforeEnter: routerGuardService.validateDashboardEventsSingle
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: AddPhotoPage
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/reset-password', '/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (loggedIn) {
    const userData = JSON.parse(loggedIn);
    const allowedRoles = ['administrator', 'organizer', 'sponsor'];


    if (
      (to.name === 'dashboardEventsList' || to.name === 'dashboardEventsSingle') &&
      !allowedRoles.includes(userData.role)
    ) {
      return next('/');
    }
  }

  next();
});
export default router;
