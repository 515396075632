<template>
  <q-form @submit="handleFormSubmit" class="q-gutter-sm integration-form">
    <q-input v-model="form.name" :label="$t('userPanel.userData.name')" type="text" lazy-rules :rules="[required, short]" autocomplete="name" />
    <q-input v-model="form.surname" :label="$t('userPanel.userData.surname')" type="text" lazy-rules :rules="[required, short]" autocomplete="family-name" />
    <q-input v-model="form.payoutEmail" :label="$t('userPanel.userData.email')" type="email" lazy-rules :rules="[required, isEmail, short]" disable />
    <q-select v-model="form.countryCode" :options="countryOptions" :label="$t('userPanel.userData.country')" lazy-rules :rules="[isOptionSelected]" autocomplete="country" />
    <q-input v-model="form.street" :label="$t('userPanel.userData.adress')" :rules="[short, required]" autocomplete="street-address" />
    <q-input v-model="form.city" :label="$t('userPanel.userData.city')" :rules="[short, required]" autocomplete="address-level2" />
    <q-input v-model="form.postalCode" :label="$t('userPanel.userData.postcode')" lazy-rules :rules="[required, isPostalCode]" autocomplete="postal-code" />
    <q-select v-model="form.language" :label="$t('userPanel.integration.language')" :options="languageOptions" :rules="[isOptionSelected]" />
    <q-input v-model="form.taxId" :label="$t('userPanel.integration.taxId')" lazy-rules :rules="[required]" />
    <q-input v-model="form.bankAccountIban" :label="$t('userPanel.integration.bankAccountIban')" lazy-rules :rules="[required, isValidIban]" />
    <q-select v-model="form.bankAccountCurrency" :label="$t('userPanel.integration.bankAccountCurrency')" :options="currencyOptions" :rules="[isOptionSelected]" />
    <q-btn :label="$t('userPanel.integration.submit')" type="submit" color="primary" />
  </q-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Notify } from "quasar";
import { validationMethods as validate } from '@/services/validation.service';

export default {
  props: {
    countries: Array,
    user: Object,
  },
  data() {
    return {
      form: {
        name: "",
        surname: "",
        payoutEmail: this.user.user_email,
        countryCode: "",
        street: "",
        postalCode: "",
        city: "",
        language: "",
        bankAccountIban: "",
        bankAccountCurrency: "",
        taxId: "",
      },
      currencyOptions: [
        { label: "USD", value: "USD" },
        { label: "EUR", value: "EUR" },
        { label: "PLN", value: "PLN" },
        { label: "GBP", value: "GBP" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    languageOptions() {
      return [
        { label: this.$t("languages.english"), value: "en" },
        { label: this.$t("languages.polish"), value: "pl" },
      ];
    },
    countryOptions() {
      if (!this.countries || this.countries.length === 0) {
        return [];
      }

      return Object.entries(this.countries).map(([isoCode, name]) => ({
        label: name,
        value: isoCode,
      }));
    },
  },
  methods: {
    ...mapActions("auth", ["integrateUseme"]),
    required(val) {
      return validate.required(val, this.$t)
    },
    isOptionSelected(val) {
      return validate.isOptionSelected(val, this.$t)
    },
    short(val) {
      return validate.short(val, this.$t);
    },
    isEmail(val) {
      return validate.isEmail(val, this.$t);
    },
    isValidIban(val) {
      return validate.isValidIban(val, this.$t);
    },
    async handleFormSubmit() {
      try {
      const formData = {
      ...this.form,
      countryCode: this.form.countryCode.value,
      language: this.form.language.value,
      bankAccountCurrency: this.form.bankAccountCurrency.value,
    };

    await this.integrateUseme(formData);

         Notify.create({
          icon: "check",
          color: "positive",
          message: this.$t("success.integration"),
          timeout: 5000,
        });
      } catch (error) {
        const message =
          error.response?.data?.message ===
            "userPanel.integration.integrationValidation"
            ? "userPanel.integration.integrationValidation"
            : "error.integration";

        Notify.create({
          icon: "error",
          color: "negative",
          message: this.$t(message),
          timeout: 8000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-form {
  width: 100%;
  max-width: 400px;

  button {
    display: flex;
    justify-self: center;
  }
}
.email-field :deep input {
  background-color: #efefef
}
@media screen and (min-width: 1024px) {
  .integration {
    &-form {
      width: 400px;
    }
  }
}
</style>