<template>
  <SummaryCard :title="$t('dashboard.main.salesReport')">
    <template #content>
      <div class="stats-container">
        <SummaryCardSalesItem
          v-for="item in statsItems"
          :key="item.id"
          :icon="item.icon"
          :label="item.label"
          :value="item.value"
        />
        <div class="stat-item">
          <q-btn @click="goToWallet" class="stats-button" color="primary">{{
            $t("dashboard.main.withdraw")
          }}</q-btn>
        </div>
      </div>
    </template>
  </SummaryCard>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SummaryCard from "./SummaryCard.vue";
import SummaryCardSalesItem from "./SummaryCardSalesItem.vue";

export default {
  name: "SummaryCardSales",
  components: {
    SummaryCard,
    SummaryCardSalesItem,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("dashboardEvents", ["totalOrganizatorCommission"]),
    ...mapState("global", [
      "wallet",
      "walletSumOfAllTime",
      "navigationOptions",
    ]),
    statsItems() {
      return [
        {
          id: 1,
          icon: "fas fa-shopping-cart",
          label: this.$t("dashboard.main.totalOrders"),
          value: this.event.ordersSummary.totalOrders,
        },
        {
          id: 2,
          icon: "fas fa-money-bill-wave",
          label: this.$t("dashboard.main.totalAlbumSalesAmount"),
          value: `${this.event.ordersSummary.totalSalesValue}${this.event.ordersSummary.currency}`,
        },
        {
          id: 3,
          icon: "fas fa-chart-line",
          label: this.$t("dashboard.main.totalAlbumsSold"),
          value: this.event.ordersSummary.totalAlbumsSold || 0,
        },
        {
          id: 4,
          icon: "fas fa-chart-bar",
          label: this.$t("dashboard.main.totalPhotosSold"),
          value: this.event.ordersSummary.totalPhotosSold || 0,
        },
        {
          id: 5,
          icon: "fas fa-wallet",
          label: this.$t("dashboard.main.totalCommissionEarned"),
          value: `${this.totalOrganizatorCommission}`,
        },
      ];
    },
  },
  created() {
    this.getUserWalletData();
  },
  methods: {
    ...mapActions({
      getUserWalletData: "global/getUserWalletData",
    }),
    goToWallet() {
      this.$router.push("/userPanel?wallet=1");
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-container {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 0 12px;
  grid-template-columns: 1fr 1fr;
  min-height: 370px;
}

.stats-button {
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
</style>