import { createStore } from "vuex";
import { auth } from "./auth.module";
import { global } from "./global.module";
import { dashboardEvents } from "./dashboardEvents.module";
import { dashboardCharts } from "./dashboardCharts.module";

const store = createStore({
  modules: {
    auth,
    global,
    dashboardEvents,
    dashboardCharts
  },
});

export default store;
