import AuthService from '../services/auth.service';
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    getUserInfo({ commit }, userId) {
      return AuthService.getUserInfo(userId).then(
        user => {
          commit('setUser', user);
          return Promise.resolve(user);
        })
    },
    integrateUseme({ commit }, formData) {
      return AuthService.integrateUseme(formData).then(
        response => {
          commit('updateBillingSuccess', { useme_email: formData.payoutEmail });
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updateBilling({ commit }, { formData, userId }) {
      return AuthService.update(formData, userId).then(
        response => {
          commit('updateBillingSuccess', formData);
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    changeUserData({ commit }, user) {
      commit('setUser', user);
    },
    resetPassword(_, { email, key, newPassword, newPasswordConfirmation }) {
      return AuthService.resetPassword(email, key, newPassword, newPasswordConfirmation).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    updateBillingSuccess(state, billingData) {
      if (state.user) {
        state.user = { ...state.user, ...billingData };
      }
    },
    setUser(state, user) {
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
