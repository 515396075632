<template>
  <div v-if="!isUserLoaded" class="loading-container">
    <q-spinner color="primary" size="3em" />
  </div>

  <div v-if="this.user.useme_email === '' && isUserLoaded" class="integration-wrapper">
    <div class="integration-information">
      <h4 class="text-h5">
        {{ $t("userPanel.integration.informationTitle") }}
      </h4>
      <p>{{ $t("userPanel.integration.description") }}</p>
      <p>{{ $t("userPanel.integration.registerWays") }}</p>
      <p>
        <span class="text-bold">
          {{ $t("userPanel.integration.recommendedOption") }}
        </span>
        <br />
        1. {{ $t("userPanel.integration.optionOne") }}
        <span>
          <a href="https://useme.com/en/users/password/recover/">
            {{ $t("userPanel.integration.resetPasswordLink") }}
          </a>
        </span>
        {{ $t("userPanel.integration.optionOneAdditional") }}
      </p>
      <p>
        2. {{ $t("userPanel.integration.optionTwo") }}
      </p>
      <h4 class="text-h5 q-mb-sm">
        {{ $t("userPanel.integration.important") }}
      </h4>
      <p class="q-mb-xs">
        {{ $t("userPanel.integration.termsAcceptance") }}
        <br /><br />
        {{ $t("userPanel.integration.moreInfo") }}:
      </p>
      <br />
      <a href="https://help.useme.com/en/useme-terms-and-conditions">
        https://help.useme.com/en/useme-terms-and-conditions
      </a>
    </div>
    
    <IntegrationForm
      :user="user"
      :countries="countries"
    />
  </div>

  <IntegrationSuccessInformation v-else-if="this.user.useme_email !== '' && isUserLoaded" />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import api from "@/api/api.js";
import IntegrationForm from "./IntegrationForm.vue";
import IntegrationSuccessInformation from "./IntegrationSuccessInformation.vue";

export default {
  name: "IntegrationCard",
  components: { IntegrationForm, IntegrationSuccessInformation },
  data() {
    return {
      countries: null,
      isLoading: true,
      isUserLoaded: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters("global", ["getCountriesList"]),
  },
  methods: {
    ...mapActions("auth", ["getUserInfo"]),
    async fetchCountries() {
      try {
        const response = await api.getCountries();
        this.countries = response.data.countries;
      } catch (error) {
        this.$q.notify({
          icon: "warning",
          message: this.$t("ERR_NETWORK") || error,
          timeout: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchUserInfo() {
      await this.getUserInfo(this.user.id);
      this.isUserLoaded = true;
    },
  },
  async mounted() {
    await this.fetchUserInfo();
    await this.fetchCountries();
  },
};
</script>

<style lang="scss" scoped>
.integration {
  &-wrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
  &-information {
    width: 100%;
    max-width: 400px;
    padding-bottom: 2rem;
  }
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem
}
@media screen and (min-width: 1024px) {
  .integration {
    &-wrapper {
      margin: 0 auto;
      padding: 1rem 2rem;
      flex-direction: row;
      gap: 4rem;
      align-items: flex-start;
    }
  }
}
</style>
