<template>
  <q-form @submit.prevent="$emit('form-submit')" class="event-form">
    <q-input
      v-model="form.eventTitle"
      :label="$t('events.form.eventTitle')"
      :rules="[required]"
      hide-bottom-space
    />

    <template v-if="!(isEditing && !isUnverifiedEvent(eventId))">
      <q-select
        clearable
        filled
        v-model="form.eventCountry"
        :options="countriesOptions"
        :rules="[required]"
        :label="$t('common.country')"
        class="select-input"
      />

      <q-input
        v-model="form.city"
        :label="$t('common.city')"
        :rules="[required]"
        hide-bottom-space
      />
    </template>

    <div class="row q-col-gutter-md">
      <q-input
        v-model="form.eventDate"
        :label="$t('events.eventStart')"
        @click="showDatePicker = true"
        :rules="[required]"
        class="col-12 col-sm-6"
        hide-bottom-space
      >
        <template #append>
          <q-icon name="event" @click="showDatePicker = true" />
        </template>
        
        <EventDatePicker
          v-model="form.eventDate"
          v-model:isOpen="showDatePicker"
        />
      </q-input>

      <div
        class="col-12 col-sm-6"
        v-for="field in numericFields"
        :key="field.key"
      >
        <q-input
          v-model.number="form[field.key]"
          :label="$t(field.label)"
          type="number"
          :min="field.min"
          :step="field.step"
          :rules="field.key === 'authorsCommission' ? [] : [required, minPrice(field.min)]"
          hide-bottom-space
          class="number-input"
        />
      </div>
    </div>

    <q-file
      v-model="form.image"
      :label="$t('events.form.image')"
      accept=".jpeg, .jpg, .png"
      max-file-size="5242880"
      :rules="[imageRequired]"
      hide-bottom-space
      @rejected="onFileRejected"
    >
      <template #append>
        <q-icon name="cloud_upload" />
      </template>
    </q-file>

    <q-checkbox
      v-model="form.hasBib"
      :label="$t('events.form.hasBib')"
      class="flex self-start q-mt-md q-ml-sm"
      left-label
    />

    <div class="q-mt-xl q-mb-lg flex justify-center">
      <q-btn
        type="submit"
        color="primary"
        :label="$t(isEditing ? 'events.form.update' : 'events.form.submit')"
        :loading="isLoading"
      />
    </div>
  </q-form>
</template>

<script>
import { Notify } from "quasar";
import { mapState } from "vuex";
import EventDatePicker from "./EventDatePicker";

export default {
  name: "EventForm",
  components: {
    EventDatePicker,
  },
  props: {
    eventId: {
      type: Number,
      default: null,
    },
    formData: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    countriesOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      unverifiedEvents: (state) => state.dashboardEvents.unverifiedEvents,
    }),
  },
  data() {
    return {
      form: { ...this.formData },
      showDatePicker: false,
      numericFields: [
        { key: "photoPrice", label: "events.form.photoPrice", step: "0.01", min: "2" },
        { key: "albumPrice", label: "events.form.albumPrice", step: "0.01", min: "9" },
        {
          key: "authorsCommission",
          label: "events.form.authorsCommission",
          step: "0.1",
        },
      ],
    };
  },
  watch: {
    formData: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.form)) {
          this.form = { ...newVal };
        }
      },
    },
    form: {
      handler(newVal) {
        this.$emit("update", newVal);
      },
      deep: true,
    },
  },
  methods: {
    required(val) {
      return !!val || this.$t("validation.required");
    },
    minPrice(min) {
      return val => {
        return val >= Number(min) || this.$t('validation.minPrice', { min });
      };
    },
    imageRequired(val) {
      return this.isEditing || !!val || this.$t("validation.required");
    },
    isUnverifiedEvent(eventId) {
      return this.unverifiedEvents.some((event) => event.id === eventId);
    },
    onFileRejected(rejectedEntries) {
      const firstRejected = rejectedEntries[0];
      if (firstRejected) {
        let message = "";
        switch (firstRejected.failedPropValidation) {
          case "accept":
            message = this.$t("events.form.fileFormat");
            break;
          case "max-file-size":
            message = this.$t("events.form.fileSize");
            break;
          default:
            message = this.$t("events.form.upload");
            break;
        }

        Notify.create({
          icon: "warning",
          color: "negative",
          message,
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  :deep(.q-field__inner) {
    background: #f8f8f8;
    padding: 10px;
  }

  :deep(.select-input),
  :deep(.select-input .q-field__inner) {
    padding: 10px 0;
  }

  .number-input :deep {
    .q-field__native[type="number"]:invalid + .q-field__label {
      transform: none !important;
    }

    .q-field__native[type="number"]:focus + .q-field__label {
      transform: translateY(-40%) scale(0.75) !important;
    }
  }
}
</style>