import api from "@/api/api.js";

class AuthService {
  getUserInfo(userId) {
    return api
      .getUserInfo(userId)
      .then(async (response) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user ? user.jwt_token : null;
        const iat = user ? user.iat : null;
        const expires_in = user ? user.expires_in : null;

        if (response.data.data) {
          const updatedUser = {
            ...response.data.data,
            jwt_token: token,
            iat: iat,
            expires_in: expires_in,
            token_type: "Bearer"
          };
          localStorage.setItem("user", JSON.stringify(updatedUser));
        }

        return response.data.data;
      });
  }
  integrateUseme(integrationData) {
    return api.integrateUseme({ integrationData });
  }
  update(billingData, userId) {
    return api.userEdit({
      billing_address_1: billingData.adress,
      billing_address_2: billingData.address_2,
      billing_first_name: billingData.billing_first_name,
      billing_last_name: billingData.billing_last_name,
      billing_company: billingData.billing_company,
      billing_city: billingData.city,
      billing_state: billingData.billing_state,
      billing_postcode: billingData.postcode,
      billing_country: billingData.billing_country,
      billing_email: billingData.billing_email,
      billing_phone: billingData.phone_number
    }, userId)
  }
  login(user) {
    return api
      .userLogin({
        email: user.email,
        password: user.password,
      })
      .then(async (response) => {
        if (response.data.token_data && response.data.profile) {
          const user = {
            ...response.data.token_data,
            ...response.data.profile,
          };
          localStorage.setItem("user", JSON.stringify(user));
        }
        const larToken = await api.getToken({
          email: user.email,
          password: user.password,
        });
        localStorage.setItem("larToken", JSON.stringify(larToken.data.data.lar_token));
        return response.data.profile;
      });
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("larToken");
  }
  register(user) {
    return api.userRegistration({
      username: user.username,
      email: user.email,
      phoneNumber: user.phoneNumber,
      password: user.password,
      referralGear: user.gear,
      referralNumber: user.runnerNumber,
      accountType: user.accountType,
      portfolio: user.portfolio,
    });
  }
  sendResetLink(email) {
    return api.sendResetLink(email);
  }
  resetPassword(email, key, newPassword, newPasswordConfirmation) {
    return api.resetPassword(email, key, newPassword, newPasswordConfirmation);
  }
}
export default new AuthService();
