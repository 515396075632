import api from '@/api/api';

export const dashboardCharts = {
  namespaced: true,
  state: {
    viewsData: [],
    salesData: [],
    ordersData: [],
    viewsLoading: false,
    viewsError: null,
    salesLoading: false,
    salesError: null,
    ordersLoading: false,
    ordersError: null,
    totalViews: 0
  },
  actions: {
    async fetchViewsByTimePeriod({ commit }, { wpRunId, timePeriod }) {
      commit('setViewsLoading', true);
      commit('setViewsError', null);

      try {
        const response = await api.getEventViewsChartDataByTimeFrame(parseInt(wpRunId), timePeriod);

        commit('setViewsData', response.data.data);
        commit('setTotalViews', response.data.totalViews);
      } catch (error) {
        commit('setViewsError', error.message);
      } finally {
        commit('setViewsLoading', false);
      }
    },
    async fetchSalesData({ commit, rootState }, { wpRunId, timePeriod, type }) {
      commit('setSalesLoading', true);
      commit('setSalesError', null);

      try {
        const currency = rootState.dashboardEvents.currency;

        const response = await api.getEventChartDataByTimeFrame(wpRunId, timePeriod, currency, type);
        commit('setSalesData', response.data.data);
      } catch (error) {
        commit('setSalesError', (error.response?.data?.message) || error);
      } finally {
        commit('setSalesLoading', false);
      }
    },
    async fetchOrdersData({ commit, rootState }, { wpRunId, timePeriod, type }) {
      commit('setOrdersLoading', true);
      commit('setOrdersError', null);

      try {
        const currency = rootState.dashboardEvents.currency;

        const response = await api.getEventChartDataByTimeFrame(wpRunId, timePeriod, currency, type);
        commit('setOrdersData', response.data.data);
      } catch (error) {
        commit('setOrdersError', (error.response?.data?.message) || error);
      } finally {
        commit('setOrdersLoading', false);
      }
    }
  },
  mutations: {
    setViewsData(state, data) {
      state.viewsData = data;
    },
    setSalesData(state, data) {
      state.salesData = data;
    },
    setOrdersData(state, data) {
      state.ordersData = data;
    },
    setViewsLoading(state, loading) {
      state.viewsLoading = loading;
    },
    setSalesLoading(state, loading) {
      state.salesLoading = loading;
    },
    setOrdersLoading(state, loading) {
      state.ordersLoading = loading;
    },
    setViewsError(state, error) {
      state.viewsError = error;
    },
    setSalesError(state, error) {
      state.salesError = error;
    },
    setOrdersError(state, error) {
      state.ordersError = error;
    },
    setTotalViews(state, data) {
      state.totalViews = data;
    }
  },
};
