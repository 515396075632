<template>
  <q-dialog
    @before-hide="$emit('close')"
    @before-show="initializeDialog"
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down"
    class="event"
  >
    <q-card class="event-card">
      <q-card-section class="flex justify-between">
        <div class="text-h6">
          {{ $t(isEditing ? "events.editEvent" : "events.addEvent") }}
        </div>
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">{{
            $t("common.close")
          }}</q-tooltip>
        </q-btn>
      </q-card-section>

      <q-card-section>
        <div
          v-if="loadingEventData"
          class="flex justify-center items-center q-pa-lg"
        >
          <q-spinner color="primary" size="3em" />
        </div>

        <EventForm
          v-show="!loadingEventData"
          :form-data="formData"
          :is-editing="isEditing"
          :event-id="eventId"
          :is-loading="isLoading"
          :countries-options="countriesOptions"
          @form-submit="handleSubmit"
          @update="updateFormData"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { date } from "quasar";
import { mapActions, mapState } from "vuex";
import { Notify } from "quasar";
import EventForm from "./EventForm.vue";

export default {
  name: "EventDialog",
  components: { EventForm },
  props: {
    eventId: {
      type: Number,
      default: null,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: this.getInitialFormState(),
      isLoading: false,
      loadingEventData: false,
    };
  },
  computed: {
    ...mapState({
      countriesList: (state) => state.global.countriesList,
      unverifiedEvents: (state) => state.dashboardEvents.unverifiedEvents,
    }),
    countriesOptions() {
      return this.countriesList;
    },
    isEditing() {
      return !!this.eventId;
    },
  },
  methods: {
    ...mapActions({
      createEventVerification: "dashboardEvents/createEventVerification",
      updateEvent: "dashboardEvents/updateEvent",
      getEventFormData: "dashboardEvents/getEventFormData",
      getCountries: "global/getCountries",
    }),
    getInitialFormState() {
      return {
        eventTitle: "",
        eventCountry: null,
        city: "",
        eventDate: null,
        photoPrice: null,
        albumPrice: null,
        authorsCommission: null,
        image: null,
        hasBib: false,
      };
    },
    updateFormData(newData) {
      this.formData = { ...this.formData, ...newData };
    },
    async initializeDialog() {
      this.formData = this.getInitialFormState();

      if (this.eventId) {
        await this.fetchEventData();
      }
    },
    async fetchEventData() {
      this.loadingEventData = true;

      try {
        const action = this.isUnverifiedEvent(this.eventId)
          ? "verification"
          : "event";

        const eventData = await this.getEventFormData({
          action,
          eventId: this.eventId,
        });

        this.formData = this.transformEventData(eventData);
      } catch (error) {
        this.showNotification("events.form.fetchError", "negative");
      } finally {
        this.loadingEventData = false;
      }
    },
    transformEventData(eventData) {
      return {
        eventTitle: eventData.eventTitle,
        eventDate: eventData.eventDate,
        city: eventData.eventCity,
        eventCountry: this.mapCountryById(eventData.eventCountry),
        photoPrice: eventData.organizerPhotoPrice,
        albumPrice: eventData.organizerAlbumPrice,
        authorsCommission: eventData.organizerAuthorCommission,
        image: null,
        hasBib: eventData.hasBib,
      };
    },
    isUnverifiedEvent(eventId) {
      return this.eventId && this.unverifiedEvents.some((event) => event.id === eventId) || !this.eventId;
    },
    mapCountryById(countryId) {
      return (
        this.countriesList.find((country) => country.id === countryId) || null
      );
    },
    createFormData() {
      const formData = new FormData();

      let dateObject;
      if (/^\d{8}$/.test(this.formData.eventDate)) {
        dateObject = date.extractDate(this.formData.eventDate, "YYYYMMDD");
      } else {
        dateObject = this.formData.eventDate
      }

      const fields = {
        event_title: this.formData.eventTitle,
        event_date: date.formatDate(dateObject, "YYYY/MM/DD"),
        photo_price: this.formData.photoPrice,
        album_price: this.formData.albumPrice,
        authors_commission: this.formData.authorsCommission,
        has_bib: this.formData.hasBib,
      };

      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.formData.image instanceof File) {
        formData.append("image", this.formData.image);
      }

      if (this.isUnverifiedEvent(this.eventId)) {
        formData.append("city", this.formData.city);
        formData.append("event_country", this.formData.eventCountry.id);
      }

      return formData;
    },
    async handleSubmit() {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const formData = this.createFormData();
        if (this.isEditing) {
          const action = this.isUnverifiedEvent(this.eventId)
            ? "verification"
            : "event";
          await this.updateEvent({
            action,
            eventId: this.eventId,
            eventData: formData,
          });
          this.$emit("update-event");
        } else {
          await this.createEventVerification(formData);
          this.$emit("create-event");
        }

        this.$emit("close");
        this.formData = this.getInitialFormState();
        this.showNotification(
          this.isEditing ? "events.form.updateSuccess" : "events.form.success",
          "positive"
        );
      } catch (error) {
        this.showNotification(
          error?.message || "events.form.error",
          "negative"
        );
      } finally {
        this.isLoading = false;
      }
    },
    showNotification(message, type) {
      Notify.create({
        message: this.$t(message),
        type,
        position: "bottom",
        timeout: 3000,
      });
    },
  },
  created() {
    if (this.countriesList.length < 1) {
      this.getCountries();
    }
  },
};
</script>

<style lang="scss" scoped>
.event {
  &-card {
    min-height: 500px;
    padding-right: 10px;
    overflow: auto;

    @media (min-width: $breakpoint-xs-max) {
      min-width: 600px;
    }
  }
}
</style>