import store from '@/store'
import SessionService from '@/services/session.service';
import Notify from 'quasar/src/plugins/Notify.js';
import i18n from '@/lang';

const validateDashboardEventsSingle = async (to, from, next) => {
  const eventId = to.params.eventId;
  const currency = store.getters['dashboardEvents/getCurrency'];

  if (!eventId) {
    Notify.create({
      type: "negative",
      message: i18n.global.t('error.invalidEvent'),
      position: "bottom",
    });

    return next({ name: 'dashboardEventsList' });
  }

  try {
    const eventData = await store.dispatch('dashboardEvents/fetchEventById', {
      eventId,
      currency
    });

    if (!eventData || !eventData.id) {
      Notify.create({
        type: "negative",
        message: i18n.global.t('error.invalidEvent'),
        position: "bottom",
      });
      return next({ name: 'dashboardEventsList', query: { invalidEvent: 'true' } });
    }

    if (!validateEventStatus(eventData, next)) {
      return;
    }

    next();
  } catch (error) {
    Notify.create({
      type: "negative",
      message: i18n.global.t('error.fetchEvents'),
      position: "bottom",
    });
    next({ name: 'dashboardEventsList', query: { fetchError: 'true' } });
  }
};

const validateEventStatus = (eventData, next) => {
  if (!eventData.eventStatus) {
    return true;
  }

  if (eventData.eventStatus === 'unverified') {
    Notify.create({
      type: "negative",
      message: i18n.global.t('error.eventUnverified'),
      position: "bottom",
    });
    return next({ name: 'dashboardEventsList', query: { invalidEvent: 'true' } });
  }

  if (eventData.eventStatus === 'verified') {
    return true;
  }
}

const validatePasswordReset = (to, from, next) => {
  const { key, email } = to.query;

  if (key && email) {
    SessionService.handleResetQuery(key, email);
    return next();
  }

  if (!SessionService.hasResetParams()) {
    return next('/login');
  }

  next();
};

const handleTabSwitch = (to, from, next) => {
  const validTabs = ["settings", "images", "integration"];
  if (!to.query.tab || !validTabs.includes(to.query.tab)) {
    next({
      name: 'userPanel',
      query: { tab: 'settings' }
    });
  } else {
    next();
  }
}

export default {
  validatePasswordReset,
  validateDashboardEventsSingle,
  handleTabSwitch
};