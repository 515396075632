export default {
  ERR_NETWORK:
    'Przepraszamy, wystąpił problem sieciowy, spróbuj za jakiś czas..',
  ERR_BAD_RESPONSE:
    'Nieprawidłowa odpowiedź serwera. Przepraszamy, spróbuj ponownie, jeśli się nie uda, wróć za jakiś czas.',
  login: {
    loginTitle: 'Logowanie',
    emailInfo:
      'Jeśli chcesz uzyskać prowizję za wrzucone zdjęcia, podaj adres e-mail powiązany z kontem paypal',
    email: 'Email',
    phoneNumberInfo: 'Aby móc wypłacać pieniądze, podaj swój numer telefonu.',
    phoneNumber: 'Numer telefonu',
    password: 'Hasło',
    repassword: 'Powtórz hasło',
    forgotPassword: 'Nie pamiętasz hasła?',
    loginButton: 'Zaloguj się',
    loginNotify: 'Zalogowano!',
    register: 'Rejestracja',
    username: 'Nazwa użytkownika',
    registration: 'Zarejestruj Się',
    createAccount: 'Załóż konto',
    referal: 'Dodaj polecenie',
    referalTitle: 'Dodaj polecającego biegacza',
    referalDesc:
      '(opcjonalne) Możesz podać numer znajomego biegacza - jeśli wszyscy jego znajomi dodadzą co najmniej 50 zdjęć innych osób, dostanie 30% zniżki!',
    runnerNumber: 'Numer biegacza',
    accountType: 'Typ konta',
    supporter: 'Kibic',
    photographer: 'Fotograf',
    portfolioTitle:
      'Jestem profesjonalnym fotografem, chcę otrzymywać wynagrodzenie za swoje zdjęcia. Zgłaszam się do weryfikacji - Link do mojego portfolio:',
    portfolio: 'Portfolio',
  },
  recoverPassword: {
    recoverDescription:
      'Proszę wpisać adres e-mail użyty podczas rejestracji konta, wyślemy Ci link do odzyskania hasła',
    recoverButton: 'Odzyskaj hasło',
    recoverGoBack: 'Ukryj odzyskiwanie hasła',
    recoverSuccess:
      'Jeśli konto z tym adresem e-mail istnieje, link do odzyskania hasła został wysłany',
    recoverError: 'Wystąpił błąd podczas wysyłania linku do odzyskania hasła',
    title: 'Ustaw nowe hasło',
    resetButton: 'Zmień hasło',
    repeat: 'Powtórz hasło',
    resetInfo: 'Wymagania bezpieczeństwa hasła:',
    requirements1: 'min 8 znaków',
    requirements2: 'min 1 duża litera',
    requirements3: 'min 1 mała litera',
    requirements4: 'min 1 cyfra',
    resetSuccess: 'Hasło zmienione! Można się zalogować ponownie.',
    invalidEmail: 'Niepoprawny email',
    invalidKey: 'Klucz odzyskania hasła wygasł. Poproś o nowy email resetujący.',
    validation: 'Wymogi bezpieczeństwa hasła niespełnione.'
  },
  validation: {
    required: 'Pole jest obowiązkowe',
    minValue: 'Minimalna ilość znaków 3',
    email: 'Wpisz poprawny email',
    countryRequired: 'Wybierz kraj',
    phoneNumber: 'Wpisz poprawny numer telefonu',
    samePassword: 'Hasła powinne być identyczne',
    completeAllFields: 'Uzupełnij wszystkie dane',
    accountType: 'Typ konta jest nieprawidłowy',
    portfolio: 'Portfolio jest nieprawidłowe',
    minPrice: "Cena musi wynosić minimum {min} zł",
    postcode: 'Niepoprawny format kodu pocztowego, wymagane od 1-6 znaków',
    language: 'Proszę wybrać język',
    bankAccountIban:
      'IBAN musi mieć między 15-34 znaki i być w poprawnym formacie',
    bankAccountCurrency: 'Waluta konta bankowego musi zostać wybrana',
  },
  duplicates: {
    general:
      'Twoje ostatnie zdjęcia, które przesłałeś, są duplikatami zdjęć, które już istnieją w systemie. Następujące biegi zawierają duplikaty:',
    unknownEvent: 'Nieznany bieg',
  },
  navigation: {
    home: 'Glówna',
    addPhoto: 'Zrób zdjęcie',
    walletProfile: 'Portfel',
    myEvents: 'Moje wydarzenia',
    logout: 'Wyloguj się',
    profile: 'Twój profil',
    signAs: 'Zalogowany jako',
    signIn: 'Załoguj się',
    role: 'Rola',
  },
  events: {
    description:
      'Lista wydarzeń w których uczestniczysz, naciśnij przycisk aby przejść do panelu informacyjnego wydarzenia',
    eventStart: 'Data wydarzenia',
    albumsQty: 'Albumy',
    button: 'Przejdź do wydarzenia',
    loading: 'Ładowanie Twoich wydarzeń...',
    noEventsMessage: 'Nie masz żadnych wydarzeń',
    noOtherEvents: 'Brak innych wydarzeń',
    eventStatus: 'Status wydarzenia',
    verified: 'Zweryfikowane',
    unverified: 'Nie zweryfikowane',
    addEvent: 'Dodaj wydarzenie',
    editEvent: 'Edycja wydarzenia',
    deleteBtn: 'Usuń wydarzenie',
    deleted: 'Szkic wydarzenia usunięty',
    form: {
      eventTitle: 'Nazwa wydarzenia',
      photoPrice: 'Cena pojedyńczego zdjęcia (zł)',
      albumPrice: 'Cena albumu (zł)',
      image: 'Obraz podstrony wydarzenia (maks. 5mb)',
      submit: 'Wyślij do weryfikacji',
      authorsCommission: 'Prowizja dla autorów zdjęc profesjonalnych (%)',
      hasBib: 'Uczestniczy mają numer identyfikacyjny',
      error: 'Błąd przy tworzeniu wydarzenia, sprawdź wszystkie pola w forumalrzu.',
      success: 'Wydarzenie utworzone pomyślnie!',
      fileFormat: 'Zły format. Dozwolone jpeg, jpg, png',
      fileSize: 'Obraz jest za duży, maksymalnie 5mb',
      upload: 'Błąd przy dodawaniu zdjęcia, wybierz inny obraz.',
      update: 'Zaktualizuj',
      updateSuccess: 'Wydarzenie zakutalizowane pomyślnie!',
      fetchError: 'Błąd przy pobieraniu danych wydarzenia, spróbuj ponownie.'
    },
    error: {
      notFound: 'Nie znaleziono wydarzenia',
      update: 'Błąd w trakcie edycji wydarzenia. Spróbuj ponownie. ',
      add: 'Bład przy dodawaniu wydarzenia. Spróbuj ponownie'
    },
    verifiedTab: 'Zweryfikowane',
    unverifiedTab: 'Oczekujące na weryfikację',
  },
  dashboard: {
    menu: {
      shares: 'Udostępnienia',
      totalOrders: 'Zamówienia',
      backBtn: 'Wróć do wydarzeń',
      stats: 'Statystyki',
      addPhoto: 'Dodaj zdjęcia',
      prefillForm: 'Formularz został uzupełniony danymi wydarzenia',
      showMore: 'Pokaż więcej',
      showLess: 'Schowaj',
      currencyBtn: 'Wybierz walutę',
      currencyInfo: 'Dane dla waluty'
    },
    main: {
      salesReport: 'Raport sprzedaży',
      earnedCommision: 'Zarobiona prowizja',
      comissionPayout: 'Dostępne do wypłaty',
      withdraw: 'Wypłać',
      albumViews: 'Wyświetlenia albumów',
      albumPhotos: 'Zdjęcia w wydarzeniu',
      photosSold: 'Kupione zdjęcia',
      startDate: 'Data wydarzenia',
      totalOrders: 'Liczba transakcji',
      totalAlbumSalesAmount: 'Suma sprzedaży',
      socialMediaSharesCount: 'Udostępnień',
      totalCommissionEarned: 'Zarobiona prowizja',
      totalCommissionPaid: 'Wypłacona prowizja',
      amountToBePaid: 'Pozostało do wypłaty',
      totalAlbumViews: 'Wyświetleń albumów',
      totalPhotosInEvent: 'Zdjęć w wydarzeniu',
      totalAlbumsSold: 'Sprzedanych albumów',
      totalPhotosSold: 'Sprzedanych pojedyńczo zdjęć',
      loading: 'Ładowanie danych wydarzenia...',
    },
    ordersTable: {
      title: 'Zrealizowane zamówienia',
      sumCommissions: 'Suma prowizji',
      orderId: 'Nr zamówienia',
      orderDate: 'Data transakcji',
      albumLink: 'Link do albumu',
      orderAmount: 'Podstawowa cena',
      coupon: 'Kupon(%)',
      currency: 'Waluta',
      paygateCommission: 'Prowizja operatora pł.',
      authorCommission: 'Prowizja autora zdjęć',
      runpixieCommission: 'Opłaty Runpixie',
      afterFeesTotal: 'Zarobiona suma',
      noOrders: 'Brak zamówień'
    },
    sharesTable: {
      title: 'Tabela udostępnień',
      shareId: 'ID',
      link: 'Link',
      copy: 'Kopiuj',
      linkCopySuccess: 'Link skopiowany do schowka!',
      linkCopyFail: 'Nie udało się skopiować linku',
      noData: 'Brak udostępnionych albumów'
    },
    charts: {
      day: 'Ostatnie 24h',
      week: 'Ostatnie 7 dni',
      month: 'Ostatnie 30 dni',
      total: 'Wszystkie',
      timeFrame: 'Zakres danych',
      weekLabel: 'Tydzień'
    }
  },
  role: {
    photographer: 'Fotograf',
    supporter: 'Kibic',
    organizer: 'Organizator',
    sponsor: 'Sponsor',
    photographer_verification: 'Fotograf (weryfikacja)',
    agent: 'Agent',
  },
  addPhoto: {
    title: 'Dodaj zdjęcia',
    blockedText: 'Aby dodać zdjęcia zaloguj się',
    subHeaderTitle: 'RunPixie - Odnajdź siebie!',
    agreement: 'Umieszczając zdjęcia, akceptujesz regulamin serwisu i licencję',
  },
  common: {
    clear: 'Wyczyść',
    add: 'Dodaj',
    subHeaderTitle: 'RunPixie - znajdź siebie!',
    country: 'Kraj',
    completeData: 'Uzupełnij dane',
    city: 'Miasto',
    selectDate: 'Wybierz datę',
    close: 'Zamknij',
    submit: 'Zatwierdż',
    selectedDate: 'Wybrana data:',
    gear: 'Bieg',
    summary: 'Podsumowanie',
    imagesCount: 'Wybrano zdjęc',
    date: 'Data',
    next: 'Dalej',
    return: 'Powrót',
    ups: 'Coś poszło nie tak!',
    turnOnThumbs: 'Włącz miniaturki w podglądzie',
    turnOnThumbsInfo:
      'Włącz tą opcje jeśli chcesz zobaczyć miniaturki w podglądzie (niezalecane przy dodawaniu większej ilości zdjęć)',
    statuteRoute: '/regulamin-korzystania-z-serwisu/',
    checkHere: 'sprawdź tutaj',
    understand: 'Rozumiem',
  },
  userPanel: {
    title: 'Panel użytkownika',
    hello: 'Cześć',
    addedPhoto: 'Dodane zdjęcia',
    inSystem: 'W systemie od',
    country: 'Kraj',
    city: 'Miasto',
    gear: 'Bieg',
    emailChanges: 'Email został zmieniony',
    badEmail: 'Wpisz poprawny email',
    walletRequestsTitle: 'Zarejestrowane prośby o wyplaty',
    walletCommissionsTitle: 'Zarejestrowane prowizje',
    blockedMoneyInfo:
      'Wartość Twoich aktualnych zgłoszeń o wypłatę (w blokadzie)',
    noPersonalDataInfo:
      'Aby zgłosić wniosek o wypłatę, uzupełnij swoje dane personalne tj. imię, nazwisko, numer telefonu, adres',
    noWithdrawIntegration:
      'Aby zgłosić wniosek o wypłatę, Twoje konto musi być zintegrowane z partnerem płatności.',
    withdrawMoney: 'Wypłać środki',
    minWithdrawValue: 'Minimalna kwota wypłaty',
    withdraw: 'Wypłać',
    wallet: 'Portfel',
    ownCommisionsUnpaid: 'Suma niewypłaconych prowizji',
    ownCommisionsAll: 'Suma wszystkich uzyskanych prowizji',
    albumName: 'Nazwa albumu',
    commission: 'Prowizja',
    status: 'Status',
    sum: 'Kwota',
    yourData: 'Twoje dane',
    phoneNumber: 'tel.',
    paymentInfo:
      'Dane niezbędne do realizacji wypłat - jeśli po zmianie, widzisz dalej stare dane, wyloguj się i zaloguj ponownie.',
    paid: 'Wypłacono',
    tableWalletHint:
      'Poniżej lista albumów z Twoimi zdjęciami które ktoś kupił. Pamiętaj, że kwota uzyskanej prowizji to (a) możliwa premia za najlepsze zdjęcie w pakiecie oraz (b) przypadająca proporcjonalnie według liczby zdjęć kwota prowizji do podziału pomiędzy autorów.',
    tableWalletHintOther:
      'Poniżej lista zarobionych prowizji za sprzedane zdjęcia i albumy. Każdy rząd odpowiada zamówieniu w którym uzyskana została prowizja.',
    tableColumns: {
      album: 'Nr sprzedanego albumu',
      commission: 'Kwota uzyskanej prowizji',
      gear: 'Wydarzenie',
      date: 'Data',
      commissionData: 'Data wypłaty prowizji',
      name: 'Nazwa',
    },
    tableTitle: 'Lista sprzedanych albumów',
    imagesTableTitle: 'Lista Twoich zdjęcia w ramach albumu',
    promptWalletInfo:
      'Wypłacasz prowizję za udzieloną RunPixie licencję na zdjęcia, które zostały wykupione przez biegaczy, zgodnie z',
    promptWalletInfo2:
      'Wypłata zalecana powyżej <strong>20 zł</strong>, wymagana po przekroczeniu <strong>500 zł</strong>. Koszt wypłaty <strong>2,5 zł</strong>. Środki niewypłacone w ciągu 2 lat przepadają',
    regulamin: 'regulaminem serwisu i umową licencyjną',
    accountData: 'Dane użytkownika',
    billingAddress: 'Dane rozliczeniowe',
    userData: {
      name: 'Imie',
      surname: 'Nazwisko',
      nrTelephone: 'Telefon',
      adress: 'Adres',
      address2: 'Adres 2',
      state: 'Województwo',
      company: 'Firma',
      country: 'Kraj',
      city: 'Miasto',
      postcode: 'Kod pocztowy',
      email: 'Email',
      role: 'Rola',
      submit: 'Zaktualizuj dane',
    },
    empty: 'Nie uzupełnione',
    completeData:
      'Uzupełnij dane (zostaniesz przekierowany do nowej karty [niezbędne będzie ponowne zalogowanie])',
    editData: 'Edytuj dane',
    solded: 'Sprzedano',
    withdrawNotConnectedInfo: 'Brak połączenia z zewnętrzną platformą płatniczą',
    connectHereLabel: 'Połącz tutaj',
    billingDataUpdating: 'Akutalizowanie...',
    integration: {
      title: 'Integracja wypłat',
      bankAccountIban: 'Number IBAN',
      bankAccountCurrency: 'Waluta konta bankowego',
      language: 'Język',
      submit: 'Załóż/Zsynchronizuj konto Useme',
      taxId: 'Pesel/Nr Podatkowy',
      userExists:
        'Konto z podanym emailem istnieje na portalu Useme. Integracja zakończona sukcesem, możesz zalogować się ponownie.',
      integrationValidation:
        'Wprowadzono błędne dane, upewnij się że dane dane typu Pesel i Iban są poprawne. Jeżeli masz już konto na Useme upewnij się że podane dane są identyczne.',
      editInformation:
        'Jeżeli w przyszłości będziesz chciał/a zmienić swoje dane, możesz to zrobić po zalogowaniu na oficjalnej stronie partnera https://useme.com',
      informationTitle: 'Informacje dotyczące integracji z Useme',
      description:
        'Aby zarabiać na swoich fotografiach, musisz mieć konto w Useme. Useme to zewnętrzna platforma do obsługi płatności, który ułatwia nam wypłatę środków, a Tobie ich odbiór. Rejestracja jest bezpłatna i prosta.',
      registerWays: 'Możesz zarejestrować się w Useme na 2 sposoby:',
      recommendedOption: 'Zalecana opcja',
      optionTwo:
        'Jeśli zdecydujesz się zarejestrować przez oficjalną stronę Useme, musisz wrócić i użyć tych samych danych w naszym formularzu, aby dokończyć integrację. Pamiętaj dane muszą być identyczne z tymi na Twoim profilu w Useme.',
      optionOne:
        'Jeśli zdecydujesz się zarejestrować przez nasz formularz, po pomyślnej rejestracji, będziesz mógł uzyskać początkowe hasło wysyłając link do resetu hasła ',
      optionOneAdditional:
        'Następnie możesz użyć tego hasła do zalogowania się do swojego konta Useme i zmienić dane według uznania.',
      important: 'Ważne',
      termsAcceptance:
        'Rejestrując się w Useme, akceptujesz ich warunki korzystania.',
      moreInfo: 'Więcej informacji znajdziesz pod linkiem',
      successTitle: 'Integracja wypłat z Useme zakończona pomyślnie!',
      successMessage: 'Twoje konto Useme zostało pomyślnie zintegrowane.',
      emailLabel: 'E-mail konta Useme:',
      approvalMessage:
        'Po zatwierdzeniu przez nasz zespół jako profesjonalny fotograf, będziesz gotowy do zarabiania na swoich zdjęciach.',
      payoutDetails:
        'Pamiętaj, że jeśli chcesz zmienić jakiekolwiek szczegóły dotyczące wypłat, możesz to zrobić za pośrednictwem portalu Useme dostępnego po zalogowaniu na',
      resetPasswordMessage:
        'Jeśli podczas integracji utworzyliśmy dla Ciebie konto w Useme, możesz zalogować się do ich portalu i zresetować hasło',
      resetPasswordLink: 'tutaj.',
      officialSite: 'oficjalnej stronie',
    },
  },
  status: {
    waiting: 'Oczekujące',
    rejected: 'Odrzucone',
    completed: 'Zrealizowane',
  },
  error: {
    badAuth: 'Błąd autoryzacji.',
    badAuthToken: 'Błąd autoryzacji - brak tokenu.',
    badAuthPriviliges: 'Błąd autoryzacji - potrzebujesz wyższych uprawnień.',
    badAuthTokenProblem: 'Błąd autoryzacji - problem z tokenem.',
    route: 'Nie możesz korzystać z tej endpointu.',
    walletNoAmmount: 'Brak pól kwoty, autora lub balansu konta.',
    code: 'Coś poszło nie tak z kodem.',
    imageFail: 'Houston, mamy problem z funkcją przesyłania obrazu.',
    usernameRequired: 'Pole nazwy użytkownika jest wymagane.',
    usernameExists: 'Użytkownik z wybraną nazwą już istnieje.',
    emailRequired: 'Pole e-mail jest wymagane.',
    phoneNumber: 'Pole numer telefonu jest wymagane',
    phoneNumberExists: 'Numer telefonu jest już w użyciu.',
    passwordRequired: 'Pole hasła jest wymagane.',
    invalidCredentials: 'Nieprawidłowa nazwa użytkownika lub hasło.',
    emailExists: 'E-mail już istnieje, spróbuj zresetować hasło.',
    addedImagesInfo: 'dodanych zdjęć:',
    renewSendImages: 'Wznów wysyłanie zdjęć',
    sendLater: 'Zamknij, wyślę później',
    sendLaterInfo:
      'Zdjęcia z kroku pierwszego zostały zaktualizowane, pozostały tylko te które nie zostały wgrane',
    catchedError: 'Wystąpił błąd',
    registerWithdrawError: 'Wystąpił błąd w rejestracji z parterem płatności',
    errorBillingForm: 'Błąd w akutalizacji danych, sprawdź wszystkie pola',
    fetchEvents: 'Wystąpił błąd przy pobieraniu listy wydarzeń. Spróbuj później.',
    noDashboardData: 'Nie wystarczająca ilość danych. Spróbuj później.',
    invalidEvent: 'To wydarzenie nie istnieje, lub nie masz do niego dostępu',
    eventUnverified: 'To wydarzenie nie zostało jeszcze zweryfikowane przez administratora',
    uploadsRestriction: 'Z powodu dużego obciążenia serwera, tylko profesjonalni fotografowie mogą aktualnie dodawać zdjęcia. Spróbuj później.'
  },
  success: {
    registeredWithReq: 'Prośba o wypłatę została zarejestrowana, dziękujemy.',
    imageAdded: 'Wszystko dobrze. Zdjęcia dodane, dziękujemy!',
    accountHasBeenCreated: 'Konto zostało założone poprawnie!',
    accountHasBeenCreatedPhotographerVerify:
      'Konto zostało założone poprawnie! Typ Twojego konta jest weryfikowany.',
    billingDataUpdated: 'Dane zaktualizowane!',
    integration: 'Integracja zakończona pomyślnie, możesz zalogować się ponownie.',
  },
  languages: {
    polish: 'Polski',
    english: 'Angielski',
  },
};
