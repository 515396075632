<template>
  <q-popup-proxy >
    <q-date v-model="selectedDate">
      <div class="row items-center justify-end q-pa-sm">
        <q-btn v-close-popup label="OK" color="primary" flat />
      </div>
    </q-date>
  </q-popup-proxy>
</template>

<script>
export default {
  props: {
    modelValue: String,
    isOpen: Boolean,
  },
  emits: ["update:modelValue", "update:isOpen"],
  data() {
    return {
      selectedDate: this.modelValue,
    };
  },
  watch: {
    modelValue(newDate) {
      this.selectedDate = newDate;
    },
    selectedDate(newDate) {
      this.$emit("update:modelValue", newDate);
    },
  },
};
</script>
