<template>
  <div class="events">
    <div class="events__wrapper q-pa-md">
      <header class="flex justify-between">
        <h2 class="text-h4 q-my-sm">{{ $t("navigation.myEvents") }}</h2>
        <q-btn
          icon="add"
          color="primary"
          :label="$t('events.addEvent')"
          class="add-button"
          @click="openEventDialog"
        />
      </header>
      <p class="text-subtitle1 q-my-lg">{{ $t("events.description") }}</p>

      <q-tabs v-model="currentTab" class="text-primary q-mb-md">
        <q-tab name="verified" :label="$t('events.verifiedTab')" />
        <q-tab name="unverified" :label="$t('events.unverifiedTab')" />
      </q-tabs>

      <div v-if="loading" class="events-loading">
        <q-spinner class="q-mx-md q-mb-md" color="primary" size="3em" />
        <p>{{ $t("events.loading") }}</p>
      </div>

      <div
        v-if="!loading && currentEvents.length === 0"
        class="events-loading__error text-subtitle1"
      >
        {{ $t("events.noEventsMessage") }}
      </div>

      <div v-if="!loading && !currentError && currentEvents.length > 0">
        <q-card
          v-for="event in currentEvents"
          :key="event.id"
          class="q-mb-md bg-grey-1"
          flat
          bordered
        >
          <div class="row no-wrap events__card">
            <div class="col-xs-12 col-sm-4 events__card-img q-py-sm">
              <q-img :src="event.thumbnail" class="full-height" />
            </div>
            <div class="col-xs-12 col-sm-7 events__card-detail">
              <div class="q-pb-md">
                <div class="text-h6 q-mb-md text-weight-bold">
                  {{ event.name }}
                </div>
                <div class="text-subtitle2">
                  <span> {{ $t("events.eventStart") }}: </span>
                  {{ formatDate(event.startDate) }}
                </div>
                <div class="text-subtitle2" v-if="event.eventStatus">
                  <span> {{ $t("events.eventStatus") }}: </span>
                  {{ $t(`events.${event.eventStatus}`) }}
                </div>
              </div>
              <div class="card-buttons">
                <q-btn
                  :event="event"
                  v-if="!event.eventStatus"
                  color="primary"
                  class="card-buttons__add q-mt-md"
                  :label="$t('events.button')"
                  @click="goToDashboard(event.id)"
                />

                <q-btn
                  v-if="user && user.id === event.postAuthor"
                  icon="edit"
                  color="secondary"
                  class="card-buttons__edit"
                  @click="editEvent(event.id)"
                />
                <q-btn
                  v-if="event.eventStatus && event.eventStatus === 'unverified'"
                  icon="delete_forever"
                  :label="$t('events.deleteBtn')"
                  color="primary"
                  class="card-button__delete"
                  :loading="isDeleting"
                  @click="handleUnverifiedEventDelete(event.id)"
                >
                  <template v-slot:loading>
                    <q-spinner color="white" size="1em" />
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card>
      </div>

      <EventDialog
        v-model="isDialogOpen"
        :event-id="selectedEvent"
        @close="handleDialogClose"
        @create-event="handleEventCreation"
        @update-event="handleEventUpdate"
      />
    </div>
  </div>
</template>

<script>
import { date } from "quasar";
import { mapActions, mapState } from "vuex";
import { Notify } from "quasar";
import EventDialog from "@/pages/dashboardEvents/dashboardEventsList/components/EventDialog.vue";

export default {
  name: "DashboardEventsListPage",
  components: {
    EventDialog,
  },
  data() {
    return {
      isDialogOpen: false,
      currentTab: "verified",
      loading: false,
      errorVerified: null,
      errorUnverified: null,
      selectedEvent: null,
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      verifiedEvents: (state) => state.dashboardEvents.events,
      unverifiedEvents: (state) => state.dashboardEvents.unverifiedEvents,
      user: (state) => state.auth.user,
    }),
    currentEvents() {
      return this.currentTab === "verified"
        ? this.verifiedEvents
        : this.unverifiedEvents;
    },
    currentError() {
      return this.currentTab === "verified"
        ? this.errorVerified
        : this.errorUnverified;
    },
  },
  methods: {
    ...mapActions("dashboardEvents", [
      "fetchUserEvents",
      "fetchUserUnverifiedEvents",
      "updateEvent",
      "deleteEventVerification",
    ]),
    async fetchVerified() {
      this.errorVerified = null;
      try {
        await this.fetchUserEvents();
      } catch (error) {
        this.errorVerified = error;
      }
    },
    editEvent(eventId) {
      this.selectedEvent = eventId;
      this.isDialogOpen = true;
    },
    async fetchUnverified() {
      this.errorUnverified = null;
      try {
        await this.fetchUserUnverifiedEvents();
      } catch (error) {
        this.errorUnverified = error;
      }
    },
    formatDate(dateString) {
      return date.formatDate(dateString, "YYYY/MM/DD");
    },
    goToDashboard(id) {
      this.$router.push(`/my-events/${id}`);
    },
    openEventDialog() {
      this.isDialogOpen = true;
    },
    async handleEventCreation() {
      await this.fetchUnverified();

      this.currentTab = "unverified";
    },
    async handleEventUpdate() {
      await this.fetchVerified();

      this.selectedEvent = null;
      this.currentTab = "verified";
    },
    async handleUnverifiedEventDelete(eventId) {
      this.isDeleting = true;

      try {
        await this.deleteEventVerification(eventId);

        Notify.create({
          type: "negative",
          message: this.$t("events.deleted"),
          position: "bottom",
        });
      } catch (error) {
        Notify.create({
          type: "negative",
          message: this.$t("events.deleteError"),
          position: "bottom",
        });
      } finally {
        this.selectedEvent = null;
        this.currentTab = "unverified";
        this.isDeleting = false;
      }
    },
    handleDialogClose() {
      this.selectedEvent = null;
      this.isDialogOpen = false;
    },
  },
  async mounted() {
    try {
      await Promise.all([this.fetchVerified(), this.fetchUnverified()]);
    } catch (error) {
      Notify.create({
        type: "negative",
        message: this.$t("error.fetchEvents"),
        position: "bottom",
      });
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.events {
  background: #fff;
  width: 100%;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  &-loading,
  &-loading__error {
    width: 300px;
    padding: 2rem 1rem 1rem 1rem;
    margin: 0 auto;
    text-align: center;
    &__error {
      border-bottom: 2px solid $primary;
    }
  }
  &__wrapper {
    width: 100%;
    max-width: 800px;
    header {
      gap: 10px;
    }
    .add-button {
      margin: 10px 0;
    }
  }
  &__card {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 14px;
    &-img {
      margin: 0 auto;
      width: 100%;
      height: 250px;
    }
    &-detail {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    &-button {
      width: 100%;
      max-width: 250px;
      align-self: center;
    }
  }
}

.card-buttons {
  display: flex;
  gap: 10px;
  flex-direction: column;
  &__edit {
    position: relative;
  }
}

.text-subtitle2 {
  margin-top: 5px;
  & span {
    font-weight: 600;
    margin-right: 6px;
  }
}

@media (min-width: $breakpoint-xs-max) {
  .events {
    &__card {
      flex-direction: row;
      &-img {
        margin-left: 10px;
        height: 200px;
        max-width: 200px;
        flex-shrink: inherit;
      }
      &-detail {
        margin: 0;
        justify-content: space-between;
        align-items: flex-start;
        flex-grow: 1;
      }
    }
  }
  .card-buttons {
    flex-direction: row;
    &__edit {
      align-self: flex-end;
    }
  }
}
</style>