<template>
  <div class="q-pa-md">
    <span class="text-label"></span>
    <q-table
      :title="$t('userPanel.tableTitle')"
      :rows="albums"
      :columns="getColumns"
      row-key="name"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-if="user.role !== 'agent'" key="albumLink" :props="props">
            <a :href="props.row.commission.url">
              <span v-html="props.row.commission.commission_id"></span>
            </a>
          </q-td>
          <q-td v-if="user.role === 'agent'" key="transaction" :props="props">
            <span v-html="props.row.commission.commission_id"></span>
          </q-td>
          <q-td v-if="user.role !== 'agent'" key="gear" :props="props">
            <span v-html="props.row.commission.run_title"></span>
          </q-td>
          <q-td key="commission" :props="props">
            {{ formatCommission(props.row.commission_value) }}
          </q-td>
          <q-td key="date" :props="props">
            {{ props.row.date }}
          </q-td>
          <q-td key="commission_paidout_date" :props="props">
            {{
              props.row.commission_paidout_date
                ? props.row.commission_paidout_date
                : "-"
            }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WalletAlbumsTable",
  props: {
    albums: {
      required: false,
      default: () => [],
      type: Array,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    getColumns() {
      const baseColumns = [
        {
          name: "commission",
          align: "center",
          label: this.$t("userPanel.tableColumns.commission"),
          field: "commission_value",
          sortable: false,
        },
        {
          name: "date",
          label: this.$t("userPanel.tableColumns.date"),
          format: (val) => `${this.formateDate(val)}`,
          align: "left",
          field: "date",
          sortable: false,
        },
        {
          name: "commission_paidout_date",
          label: this.$t("userPanel.tableColumns.commissionData"),
          align: "left",
          field: "commission_paidout_date",
        },
      ];

      if (this.user.role === "agent") {
        return [
          {
            name: "transaction",
            label: this.$t("dashboard.ordersTable.orderId"),
            field: "commission.commission_id",
            align: "left",
            sortable: false,
            classes: "bg-grey-2 ellipsis",
            headerClasses: "bg-primary text-white",
          },
          ...baseColumns,
        ];
      }

      return [
        {
          name: "albumLink",
          label: this.$t("dashboard.ordersTable.albumLink"),
          field: "commission.commission_id",
          align: "left",
          sortable: false,
          classes: "bg-grey-2 ellipsis",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "gear",
          label: this.$t("userPanel.tableColumns.gear"),
          field: "commission.run_title",
          align: "left",
          sortable: false,
        },
        ...baseColumns,
      ];
    },
  },
  methods: {
    isAuthorCommission(row) {
      return row.commission_type === "photo_author_commission";
    },
    isAgentCommission(row) {
      return row.commission_type === "agent_commission";
    },
    showImages(img, album) {
      this.$emit("showImageModal", { images: img, album_name: album });
    },
    formatCommission(value) {
      return `${Math.round(Number(value) * 100) / 100}zł`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
